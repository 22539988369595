<template>
  <div class="workTemplate">
    <div class="container">
      <div class="tree">
        <div v-if="treeData">
          <a-tree :treeData="treeData" defaultExpandAll @select="_treeSelect" />
        </div>
      </div>

      <div class="content">
        <div class="row-title">
          <span>模板（{{ templateCount }}）</span>
          <a-button type="primary" @click="templateVisible = true"
            >上传模板</a-button
          >
        </div>
        <div class="template-list template-library">
          <ul class="title-row">
            <li v-for="(item, index) in templateList">
              <div class="template-img">
                <viewer style="height:140px;width:100%;position:absolute">
                  <img :src="item.PicUrl" alt="" />
                </viewer>
              </div>
              <div class="template-title flex">
                <span>{{ item.Name }}</span>
                <a-dropdown>
                  <a-icon type="ellipsis" />
                  <a-menu slot="overlay">
                    <a-menu-item style="position:relative;overflow:hidden;">
                      <a href="javascript:;"
                        ><i class="iconfont icon-yulan"></i> 预览</a
                      >
                      <viewer
                        style="height:36px;width:150px;margin-top:-32px;position:absolute;"
                      >
                        <img
                          :src="
                            item.PicUrl +
                              '?imageMogr2/thumbnail/620x/strip/quality/50/format/webp'
                          "
                          style="width:100%;height:100%;opacity:0"
                        />
                      </viewer>
                    </a-menu-item>
                    <a-menu-item>
                      <a href="javascript:;" @click="_setTemplate(index)"
                        ><i class="iconfont icon-zhongmingming-"></i> 修改</a
                      >
                    </a-menu-item>
                    <a-menu-item>
                      <a href="javascript:;" @click="_delTemplate(index)"
                        ><i class="iconfont icon-shanchu-"></i> 删除</a
                      >
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
              <div class="created-date">{{ item.CreateTime }}</div>
            </li>
          </ul>
          <div class="pagination" v-if="templateCount > 10">
            <a-pagination
              :total="templateCount"
              style="display: inline-block"
              @change="_templatePage"
            />
          </div>
          <div
            v-if="templateList.length == 0"
            style="font-size: 18px;color: #aaa;text-align: center"
          >
            暂无数据
          </div>
        </div>
      </div>
    </div>
    <!--上传模板弹出框-->
    <a-drawer
      :title="setTemplate != -1 ? '修改模板' : '上传模板'"
      placement="right"
      width="400"
      :closable="false"
      @close="_templateClose"
      :visible="templateVisible"
    >
      <div class="label">名称</div>
      <a-input
        class="label-input"
        placeholder="请输入"
        v-model="uploadTemplateName"
      />
      <div class="label">分类</div>
      <a-tree-select
        style="width: 100%;margin: 5px 0;"
        :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
        :treeData="treeData"
        placeholder="请选择分类"
        treeDefaultExpandAll
        v-model="uploadTemplateType"
      >
        <span
          style="color: #08c"
          slot="title"
          slot-scope="{ key, value }"
          v-if="(key = '0-0-1')"
          >{{ value }}</span
        >
      </a-tree-select>
      <!--<a-select defaultValue="请选择" class="label-input" style="width: 100%" v-model="uploadTemplateType">-->
      <!--<a-select-option :value="item.Id" v-for="item in treeData">{{item.title}}</a-select-option>-->
      <!--</a-select>-->
      <div class="label">唯一标识</div>
      <a-input
        class="label-input"
        placeholder="请输入"
        v-model="uploadTemplateLabel"
      />
      <div class="label">模板图片</div>
      <img
        v-if="uploadTemplatePic"
        :src="uploadTemplatePic.FileUrl + '?imageView2/1/w/352/h/200'"
        style="width: 100%;max-height: 200px;margin: 10px 0"
        alt=""
      />
      <a-button class="label-input template-upload-btn"
        >上传图片 <input type="file" @change="_uploadTemplatePic($event)"
      /></a-button>
      <div class="upload-suggestion">
        建议图片尺寸不大于1920*1080px，大小不超过2M
      </div>
      <div class="alert-footer">
        <a-button style="margin-right:25px" @click="_templateClose"
          >取消</a-button
        >
        <a-button type="primary" @click="_uploadTemplateSub">确定</a-button>
      </div>
    </a-drawer>

    <!--图片预览-->
    <viewer :images="viewerList" v-if="showViewer">
      <img v-for="(item, index) in viewerList" :key="index" :src="item" />
    </viewer>
  </div>
</template>

<script>
const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
import Vue from 'vue'
import Viewer from 'v-viewer' //图片操作
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source'
  }
})
export default {
  name: 'workTemplate',
  data () {
    return {
      templateList: [],
      templateCount: 1,
      selectTemplateId: '',
      treeData: '',
      showViewer: false,
      templateVisible: false,
      uploadTemplateName: '',
      uploadTemplateType: '',
      uploadTemplateLabel: '',
      uploadTemplatePic: '',
      setTemplate: -1
    }
  },
  created () {
    //获取模板分类
    this.$axios.get(1206, { label: 'HomeWorkTem' }, res => {
      if (res.data.code == 1) {
        let data = [
          {
            title: '全部',
            value: '',
            key: '',
            Id: '',
            children: res.data.data
          }
        ]
        this.treeData = data
      }
    })
    //获取模板列表
    this._templateData()
  },
  methods: {
    _uploadTemplatePic (e) {
      this.__uploadFile__(e.target.files).then(res => {
        this.uploadTemplatePic = res[0]
      })
    },
    _delTemplate (index) {
      this.$axios.post(
        5836131,
        { Id: this.templateList[index].Id, UserId: userInfo.uid },
        res => {
          if (res.data.code == 1) {
            this.templateList.splice(index, 1)
            this.$message.success('删除成功')
          }
        }
      )
    },
    _setTemplate (index) {
      this.uploadTemplateName = this.templateList[index].Name //模板名称
      this.uploadTemplateType = this.templateList[index].CategoryId //模板分类
      this.uploadTemplateLabel = this.templateList[index].Label //唯一标识
      this.uploadTemplatePic = { FileUrl: this.templateList[index].PicUrl } //模板图片
      this.templateVisible = true
      this.setTemplate = index
    },
    _uploadTemplateSub () {
      let data = {
        Name: this.uploadTemplateName,
        Label: this.uploadTemplateLabel,
        CategoryId: this.uploadTemplateType,
        PicUrl: this.uploadTemplatePic.FileUrl,
        CreateUserId: userInfo.uid,
        CreateUserName: userInfo.name
      }
      if (this.setTemplate != -1) {
        data.Id = this.templateList[this.setTemplate].Id
        data.UserId = userInfo.uid
        this.$axios.post(5836132, data, res => {
          if (res.data.code == 1) {
            this.$message.success('修改成功')
            let item = {
              CategoryId: this.uploadTemplateType,
              CreateTime: new Date().toLocaleDateString(),
              CreateUserId: userInfo.uid,
              CreateUserName: userInfo.name,
              Id: data.Id,
              Label: this.uploadTemplateLabel,
              Name: this.uploadTemplateName,
              PicUrl: this.uploadTemplatePic.FileUrl
            }
            this.templateList[this.setTemplate] = item
            this._templateClose()
          } else {
            this.$message.success(res.data.message)
          }
        })
      } else {
        this.$axios.post(5836130, data, res => {
          if (res.data.code == 1) {
            this.$message.success('添加成功')
            let item = {
              CategoryId: this.uploadTemplateType,
              CreateTime: new Date().toLocaleDateString(),
              CreateUserId: userInfo.uid,
              CreateUserName: userInfo.name,
              Id: res.data.Id,
              Label: this.uploadTemplateLabel,
              Name: this.uploadTemplateName,
              PicUrl: this.uploadTemplatePic.FileUrl
            }
            this.templateList.push(item)
            this._templateClose()
          } else {
            this.$message.success(res.data.message)
          }
        })
      }
    },
    _templateClose () {
      this.templateVisible = false
      this.uploadTemplateName = '' //模板名称
      this.uploadTemplateType = '' //模板分类
      this.uploadTemplateLabel = '' //唯一标识
      this.uploadTemplatePic = '' //模板图片
      this.setTemplate = -1 //判断是否是修改模板
    },
    _templatePage (page) {
      this.templatePage = page
      this._templateData()
    },
    _treeSelect (key, e) {
      this.selectTemplateId = key[0]
      this._templateData()
    },
    _templateData () {
      this.$axios.get(
        5836127,
        { CategoryId: this.selectTemplateId, Page: this.templatePage },
        res => {
          if (res.data.code == 1) {
            this.templateList = res.data.data
            this.templateCount = res.data.count
          } else {
            this.templateList = []
            this.templateCount = 0
          }
        }
      )
    }
  }
}
</script>

<style scoped lang="less">
.workTemplate {
  height: 100%;
  .container {
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    .content {
      flex: 1;
      margin-left: 15px;
      padding: 15px;
      padding-bottom: 40px;
      border: 1px solid #dfe0e6;
      background: #fff;
      border-radius: 5px;
      .row-title {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        span {
          font-size: 16px;
        }
      }
    }
  }
}
.alert-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  button {
    width: 120px;
  }
}
.tree {
  width: 200px;
  padding: 15px;
  border: 1px solid #dfe0e6;
  border-radius: 5px;
  background: #fff;
  .tree-title {
  }
}
.template-list {
  padding-top: 15px;
  .search-div {
    .condition {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 10px;
      span {
        margin-right: 10px;
      }
    }
  }
  .icon-btn {
    font-size: 20px;
    margin-right: 8px;
    cursor: pointer;
  }

  .title-row {
    display: flex;
    flex-wrap: wrap;
    > li {
      position: relative;
      width: 140px;
      margin-right: 10px;
      margin-bottom: 15px;
      .template-img {
        height: 100px;
        overflow: hidden;
        position: relative;
      }
      .template-title {
        padding: 15px;
        padding-bottom: 0;
        font-size: 16px;
        color: #2d3e5d;
        span {
          display: inline-block;
          width: 180px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .created-date {
        padding: 0 15px;
        padding-bottom: 10px;
        color: #999;
      }
      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .pagination {
    text-align: right;
  }
}
.template-library {
  .title-row {
    > li {
      width: 200px;
      margin-right: 15px;
      margin-bottom: 15px;
      .template-img {
        height: 160px;
      }
    }
  }
}
/*
    *上传模板弹出框样式
    */
.label-input {
  margin-top: 5px;
  margin-bottom: 15px;
}
.template-upload-btn {
  position: relative;
  border-color: #1890ff;
  color: #1890ff;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.upload-suggestion {
  color: #a6a5a8;
  font-size: 12px;
}
.alert-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  button {
    width: 120px;
  }
}
.upload-btn {
  position: relative;
  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.ant-drawer-body {
  height: calc(~'100% - 55px');
}
</style>
