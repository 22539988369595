<template>
  <!--素材库-->
  <div class="HomeWork">
    <div class="condition" style="white-space: nowrap;margin-bottom: 15px;">
      <a-input
        v-model="searchMaterialName"
        style="width: 200px;margin-right: 15px;"
        placeholder="搜索名称"
      ></a-input>
      <a-button type="primary" @click="_searchMaterial">查询</a-button>
    </div>
    <div class="container" style="margin-top:0">
      <div class="tree">
        <div
          class="tree-title"
          style="cursor: pointer"
          @click="_treeSelectAccessory('')"
        >
          全部分类
        </div>
        <a-tree :treeData="materialList" @select="_treeSelectAccessory" />
      </div>
      <div class="content">
        <div class="row-title">
          <span>数量（{{ materiaData.count }}）</span>
          <a-button type="primary" class="upload-btn"
            >上传文件
            <input type="file" @change="_uploadAttachment($event)" multiple
          /></a-button>
        </div>
        <div class="template-list">
          <a-checkbox-group
            @change="_materiaIndex"
            :value="checkboxGroup"
            style="width: 100%"
          >
            <ul class="title-row">
              <li
                v-for="(item, index) in materiaData.list"
                @mouseover="_showOperation(index)"
                @mouseout="showOperation = -1"
              >
                <div
                  v-if="
                    item.FileType == 'audio/mpeg' ||
                      item.FileType == 'audio/x-aiff' ||
                      item.FileType == 'audio/mp3'
                  "
                >
                  <div class="template-img" style="position: relative">
                    <a-icon
                      type="customer-service"
                      style="font-size: 60px;color: #1890FF;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%)"
                    />
                    <a
                      :href="item.FileUrl"
                      target="_blank"
                      style="display:block;position:absolute;left:0px;top:0px;width:100%;height:100%;"
                    ></a>
                  </div>

                  <div class="template-title flex">
                    <span v-text="item.FileName"></span>
                    <a-checkbox :value="index"></a-checkbox>
                  </div>
                  <div class="created-date flex">
                    <span>{{ item.CreateTime }}</span>
                    <a-dropdown
                      :placement="'topLeft'"
                      :getPopupContainer="triggerNode => triggerNode.parentNode"
                    >
                      <i
                        class="iconfont icon-shenglve"
                        style="font-size: 20px"
                      ></i>
                      <a-menu slot="overlay">
                        <a-menu-item style="position:relative;overflow:hidden;">
                          <a href="javascript:;" @click="_openUrl(item.FileUrl)"
                            >预览</a
                          >
                        </a-menu-item>
                        <a-menu-item>
                          <a
                            href="javascript:;"
                            @click="
                              _downloadFile(
                                item.FileUrl,
                                item.FileName,
                                item.FileType
                              )
                            "
                            >下載</a
                          >
                        </a-menu-item>
                        <a-menu-item>
                          <a
                            href="javascript:;"
                            @click="_showRename(item.Id, item.FileName, index)"
                            >重命名</a
                          >
                        </a-menu-item>
                        <a-menu-item>
                          <a-popconfirm
                            title="您确定要删除吗?"
                            @confirm="_delMaterial(index, item.Id)"
                            okText="确定"
                            cancelText="取消"
                          >
                            <a href="javascript:;">删除</a>
                          </a-popconfirm>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </div>
                </div>
                <!-- 视频 -->
                <div
                  v-else-if="
                    item.FileType == 'video/ogg' ||
                      item.FileType == 'video/mp4' ||
                      item.FileType == 'video/webm' ||
                      item.FileType == 'application/x-mpegURL'
                  "
                >
                  <div class="template-img">
                    <img
                      :src="
                        item.FileUrl +
                          '?vframe/jpg/offset/0|imageView2/1/w/218/h/140'
                      "
                      alt=""
                    />
                    <a
                      :href="item.FileUrl"
                      target="_blank"
                      style="display:block;position:absolute;left:0px;top:0px;width:100%;height:100%;"
                    ></a>
                  </div>
                  <div class="template-title flex">
                    <span v-text="item.FileName"></span>
                    <a-checkbox :value="index"></a-checkbox>
                  </div>
                  <div class="created-date flex">
                    <span>{{ item.CreateTime }}</span>
                    <a-dropdown
                      :placement="'topLeft'"
                      :getPopupContainer="triggerNode => triggerNode.parentNode"
                    >
                      <i
                        class="iconfont icon-shenglve"
                        style="font-size: 20px"
                      ></i>
                      <a-menu slot="overlay">
                        <a-menu-item style="position:relative;overflow:hidden;">
                          <a href="javascript:;" @click="_openUrl(item.FileUrl)"
                            >预览</a
                          >
                        </a-menu-item>
                        <a-menu-item>
                          <a
                            href="javascript:;"
                            @click="
                              _downloadFile(
                                item.FileUrl,
                                item.FileName,
                                item.FileType
                              )
                            "
                            >下載</a
                          >
                        </a-menu-item>
                        <a-menu-item>
                          <a
                            href="javascript:;"
                            @click="_showRename(item.Id, item.FileName, index)"
                            >重命名</a
                          >
                        </a-menu-item>
                        <a-menu-item>
                          <a-popconfirm
                            title="您确定要删除吗?"
                            @confirm="_delMaterial(index, item.Id)"
                            okText="确定"
                            cancelText="取消"
                          >
                            <a href="javascript:;">删除</a>
                          </a-popconfirm>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </div>
                </div>
                <!-- 图片 -->
                <div v-else>
                  <div class="template-img">
                    <viewer style="height:100%;width:100%;position:absolute;">
                      <img :src="item.FileUrl" alt="" />
                    </viewer>
                  </div>
                  <div class="template-title flex">
                    <span v-text="item.FileName"></span>
                    <a-checkbox :value="index"></a-checkbox>
                  </div>
                  <div
                    style="position: absolute;top: 0px;right: 0px;"
                    class="created-date"
                    v-show="showOperation == index"
                  >
                    <div>
                      <a-dropdown
                        :placement="'bottomLeft'"
                        :getPopupContainer="
                          triggerNode => triggerNode.parentNode
                        "
                      >
                        <i
                          class="iconfont icon-shenglve"
                          style="font-size: 20px"
                        ></i>
                        <a-menu slot="overlay">
                          <a-menu-item
                            style="position:relative;overflow:hidden;"
                          >
                            <a href="javascript:;">预览</a>
                            <!-- <i class="iconfont icon-yulan"></i> -->
                            <viewer
                              style="height:36px;width:150px;margin-top:-32px;position:absolute;"
                            >
                              <img
                                :src="
                                  item.FileUrl +
                                    '?imageMogr2/thumbnail/620x/strip/quality/50/format/webp'
                                "
                                style="width:100%;height:100%;opacity:0"
                              />
                            </viewer>
                          </a-menu-item>
                          <a-menu-item>
                            <a
                              href="javascript:;"
                              @click="
                                _downloadFile(
                                  item.FileUrl,
                                  item.FileName,
                                  item.FileType
                                )
                              "
                              >下載</a
                            >
                          </a-menu-item>
                          <a-menu-item>
                            <a
                              href="javascript:;"
                              @click="
                                _showRename(item.Id, item.FileName, index)
                              "
                              >重命名</a
                            >
                          </a-menu-item>
                          <a-menu-item>
                            <a-popconfirm
                              title="您确定要删除吗?"
                              @confirm="_delMaterial(index, item.Id)"
                              okText="确定"
                              cancelText="取消"
                            >
                              <a href="javascript:;">删除</a>
                            </a-popconfirm>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </a-checkbox-group>
          <div style="text-align: right" v-show="materiaData.count > 20">
            <a-pagination
              style="display: inline-block;"
              showSizeChanger
              @change="_onShowSizeChange"
              :defaultPageSize="20"
              :total="materiaData.count"
            />
          </div>
          <div
            v-if="materiaData.list.length == 0"
            style="font-size: 18px;color: #aaa;text-align: center"
          >
            暂无数据
          </div>
        </div>
      </div>
    </div>
    <div class="alert-footer">
      <a-button style="margin-right:25px" @click.stop="_seleAccessoryClose"
        >取消</a-button
      >
      <a-button type="primary" @click.stop="_seleAccessoryConfirm"
        >确定</a-button
      >
    </div>
    <!--重命名弹框-->
    <a-drawer
      title="重命名"
      placement="right"
      width="400"
      :closable="false"
      @close="_cloneRename"
      :visible="cloneRename"
    >
      <div class="container" style="margin-top:0">
        <a-input placeholder="请输入" v-model="Rename" style="width: 100%" />
      </div>
      <div class="alert-footer">
        <a-button style="margin-right:25px" @click="_cloneRename"
          >取消</a-button
        >
        <a-button type="primary" @click="_confirmRename">确定</a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import workTemplate from 'pages/HomeWork/workTemplate'
const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
import QRCode from 'qrcodejs2'
import Vue from 'vue'
import Viewer from 'v-viewer' //图片操作
import 'viewerjs/dist/viewer.css'
import VueClipboard from 'vue-clipboard2' //复制
Vue.use(VueClipboard)
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source'
  }
})
export default {
  name: 'material',
  components: { workTemplate },
  data () {
    return {
      searchMaterialName: '', //素材搜索
      materiaData: [],
      seleAccessory: false,
      treeSelectAccessoryId: '', //选择附件tree选择ID
      seleQImgIndex: -1, //数据项选择附件
      seleQImgTabIndex: -1, //数据项选择附件
      addAssociationIndex: -1, //关联图片
      addAssociationTabIndex: -1, //关联图片2
      materiaTemplateIndex: [], //附件
      selectParameterIndex: -1, //选择的数据项
      selectParameterTableIndex: -1, //选择的数据项2
      checkboxGroup: [], //附件多选
      materialList: [], //素材分类
      showOperation: -1,
      materiaIndex: -1, //附件Index
      cloneRename: false, //重命名显示隐藏
      Rename: '', //重命名
      RenameId: '', //重命名Id
      RenameIndex: '' //重命名下标
    }
  },
  created () {
    //获取素材分类
    this._getCategoryIdList()
    //素材列表
    this._getMateriaList()
  },
  methods: {
    _searchMaterial () {
      this.$message.loading('加载中...')
      this.$axios.get(
        5836125,
        { CategoryId: '', limit: 20, page: 1, Name: this.searchMaterialName },
        res => {
          this.materiaData = res.data.data
          if (res.data.code == 0) {
            this.materiaData = { count: 0, list: [] }
            this.$message.destroy()
          }
        }
      )
    },
    _seleAccessoryClose () {
      this.seleAccessory = false
      this.seleQImgIndex = -1
      this.seleQImgTabIndex = -1
      this.addAssociationIndex = -1
      this.addAssociationTabIndex = -1
      this.materiaIndex = -1
      this.checkboxGroup = []
      this.$emit('fathermethod', '')
    },
    _treeSelectAccessory (key) {
      this.treeSelectAccessoryId = key[0]
      this.materiaData = { count: 0, list: [] }
      this.$axios.get(
        5836125,
        { CategoryId: this.treeSelectAccessoryId, limit: 20, page: 1 },
        res => {
          if (res.data.code == 0) {
            this.materiaData.count = 0
            this.materiaData.list = []
          } else {
            this.materiaData = res.data.data
          }
        }
      )
    },
    _getCategoryIdList () {
      this.$message.loading('加载中...')
      this.$axios.get(1206, { label: 'homeWorkMaterial' }, res => {
        if (res.data.code == 1) {
          this.$message.destroy()
          this.materialList = res.data.data
        }
      })
    },
    _getMateriaList () {
      //获取素材列表
      this.$message.loading('加载中...')
      this.$axios.get(5836125, { CategoryId: '', limit: 20, page: 1 }, res => {
        if (res.data.code == 1) {
          this.materiaData = res.data.data
          this.$message.destroy()
        }
      })
    },
    _uploadAttachment (e) {
      this.$message.loading('加载中...')
      this.__uploadFile__(e.target.files, '/qiniu/homework_token').then(
        files => {
          let data = {
            UserId: userInfo.uid,
            UserName: userInfo.name,
            Data: JSON.stringify(files),
            CategoryId: this.treeSelectAccessoryId
          }
          this.$axios.post(5836124, data, res => {
            if (res.data.code == 1) {
              this.$message.success(res.data.message)
              this.$set(
                this.materiaData,
                'list',
                this.materiaData.list.concat(res.data.data)
              )
              this.$set(
                this.materiaData,
                'count',
                this.materiaData.count + res.data.data.length
              )
              this.$message.destroy()
            } else {
              this.$message.error(res.data.message)
            }
          })
        }
      )
    },
    _showOperation (i) {
      this.showOperation = i
    },
    _openUrl (url) {
      window.open(url)
    },
    _downloadFile (url, name, type) {
      let suffix = type.split('/')[1]
      //console.log(url+'?attname='+name+'.'+suffix)
      window.open(url + '?attname=' + name + '.' + suffix)
    },
    _showRename (id, name, i) {
      this.cloneRename = true
      this.Rename = name
      this.RenameId = id
      this.RenameIndex = i
    },
    _delMaterial (i, id) {
      this.$axios.post(5836137, { Id: id }, res => {
        if (res.data.code == 1) {
          this.materiaData.list.splice(i, 1)
          this.materiaData.count = this.materiaData.count - 1
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    _onShowSizeChange (current) {
      console.log(this.searchMaterialName)
      this.$axios.get(
        5836125,
        {
          CategoryId: this.treeSelectAccessoryId,
          limit: 20,
          page: current,
          Name: this.searchMaterialName
        },
        res => {
          if (res.data.code == 1) {
            this.materiaData = res.data.data
          }
        }
      )
    },
    _seleAccessoryConfirm () {
      this.$emit('fathermethod', this.materiaIndex)
    },
    _cloneRename () {
      this.cloneRename = false
      this.Rename = ''
      this.RenameId = ''
      this.RenameIndex = ''
    },
    _materiaIndex (checkedValues) {
      let self = this
      this.materiaIndex = []
      this.checkboxGroup = checkedValues
      for (let j = 0; j < checkedValues.length; j++) {
        self.materiaIndex.push(self.materiaData.list[checkedValues[j]])
      }
    },
    _confirmRename () {
      if (this.Rename == '') {
        this.$message.error('名字不能为空！！')
        return false
      }
      this.$axios.post(
        5836138,
        { Id: this.RenameId, Name: this.Rename },
        res => {
          if (res.data.code == 1) {
            this.materiaData.list[this.RenameIndex].FileName = this.Rename
            this.$message.success(res.data.message)
            this._cloneRename()
          } else {
            this.$message.error(res.data.message)
          }
        }
      )
    }
  }
}
</script>
<style lang="less" scoped>
.HomeWork {
  height: 100%;
  .tabs {
    border-bottom: 1px solid #ccd1dc;
    border-left: 1px solid #ccd1dc;
    border-top-left-radius: 5px;
    ul {
      li {
        display: inline-block;
        width: 85px;
        padding: 6px 0;
        text-align: center;
        border: 1px solid #ccd1dc;
        border-bottom: none;
        cursor: pointer;
        border-left: 0;
        &:first-child {
          border-top-left-radius: 5px;
        }
        &:last-child {
          border-top-left-radius: 0;
          border-top-right-radius: 5px;
        }
      }
      .active {
        background: #1890ff;
        color: #fff;
        border: 1px solid #1890ff;
      }
    }
  }
}
.container {
  min-height: calc(~'100% - 60px');
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .content {
    flex: 1;
    margin-left: 15px;
    padding: 15px;
    padding-bottom: 40px;
    border: 1px solid #dfe0e6;
    background: #fff;
    border-radius: 5px;
    .row-title {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      span {
        font-size: 16px;
      }
    }

    .template-list {
      padding-top: 15px;
      .search-div {
        .condition {
          display: inline-block;
          margin-right: 20px;
          margin-bottom: 10px;
          span {
            margin-right: 10px;
          }
        }
      }
      .icon-btn {
        font-size: 20px;
        margin-right: 8px;
        cursor: pointer;
      }

      .title-row {
        display: flex;
        flex-wrap: wrap;
        > li {
          position: relative;
          width: 140px;
          margin-right: 10px;
          margin-bottom: 15px;
          .template-img {
            height: 100px;
            overflow: hidden;
            position: relative;
          }
          .template-title {
            padding: 15px;
            padding-bottom: 0;
            font-size: 16px;
            color: #2d3e5d;
            span {
              display: inline-block;
              width: 180px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .created-date {
            padding: 0 15px;
            padding-bottom: 10px;
            color: #999;
          }
          img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .pagination {
        text-align: right;
      }
    }
    .template-library {
      .title-row {
        > li {
          width: 200px;
          margin-right: 15px;
          margin-bottom: 15px;
          .template-img {
            height: 160px;
          }
        }
      }
    }
  }
  .tree {
    width: 200px;
    padding: 15px;
    border: 1px solid #dfe0e6;
    border-radius: 5px;
    background: #fff;
    .tree-title {
    }
  }
}
.status-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ddd;
  margin-right: 3px;
}
/*
    *添加题目模板弹出框样式
    */
.parameter {
  position: relative;
  width: 350px;
  padding: 0 20px;
  border-left: 8px solid #f9f9fb;
  .float {
    position: fixed;
  }
  .parameter-title {
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin-bottom: 15px;
    background: #666668;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    font-size: 14px;
  }
  .item-row {
    margin-bottom: 15px;
    .row-label {
      margin-bottom: 10px;
    }
    .row-input {
      position: relative;
      .association-img {
        width: 70px;
        height: 70px;
      }
      .shade {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.7;
      }
      .icon-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        color: #fff;
      }
      .add-association {
        display: inline-block;
        vertical-align: top;
        width: 70px;
        height: 70px;
        border: 1px dashed #e3e2e5;
        text-align: center;
        cursor: pointer;
        position: relative;
        p {
          margin-top: -15px;
          font-size: 50px;
          color: #aaa;
          font-weight: 300;
        }
        div {
          margin-top: -15px;
          font-size: 12px;
          color: #aaa;
        }
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }
  }
  .item-row-checkbox {
    margin-bottom: 20px;
  }
}
.title-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .video {
    position: relative;
    border: 1px solid #eee;
    .video-msg {
      padding: 10px;
      border-top: 1px solid #f3f1f3;
      .video-name {
        font-size: 16px;
      }
      .video-length {
        color: #aaa;
        font-size: 14px;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;
      max-height: 165px;
    }
    .play-btn {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      color: #666;
      cursor: pointer;
    }
  }
  .audio {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    .audio-title {
      position: relative;
      flex: 1;
      .audio-upload-time {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: 12px;
        color: #aaa;
      }
    }
    .audio-file {
      width: 80px;
      padding: 10px 0;
      background: #fafafc;
      text-align: center;
      margin-left: 10px;
    }
    .audio-length {
      color: #aaa;
      font-size: 14px;
    }
  }
  .title-row-label {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
  }
  .title-row-input {
    display: inline-block;
    vertical-align: middle;
    width: 300px;
    .select-input {
      width: 100%;
      height: 30px;
      padding: 0 10px;
      line-height: 30px;
      background: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
      span {
        color: #ddd;
      }
    }
    .upload-attachment {
      position: relative;
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
    .audio,
    .video {
      margin-top: 15px;
    }
  }
}
.template-thumbnail {
  position: absolute;
  top: 15px;
  right: 15px;
  ul {
    li {
      display: inline-block;
      width: 150px;
      height: 150px;
      margin-right: 20px;
      border: 1px solid #e4e3ea;
      border-radius: 5px;
      text-align: center;
      vertical-align: top;
      p {
        margin-top: 5px;
        text-align: center;
        color: #666;
        font-size: 12px;
      }
      img {
        display: inline-block !important;
        margin: 10px 20px;
        width: 85px;
        height: 105px;
      }
      #qrcode {
        margin: 0 auto;
        margin-top: 15px;
        width: 90px;
        height: 90px;
        img {
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.question-msg {
  .title-row-input {
    ul {
      li {
        position: relative;
        display: inline-block;
        width: 100px;
        height: 100px;
        margin-right: 15px;
        margin-bottom: 15px;
        border: 1px solid #e4e4e4;
        vertical-align: top;
        .operation-btn {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: #000;
          opacity: 0.7;
          color: #fff;
          display: flex;
          align-items: center;
          span {
            position: relative;
            display: inline-block;
            flex: 1;
            padding: 4px 0;
            text-align: center;
            font-size: 12px;
            cursor: pointer;
            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
          }
        }
        .itme-empty {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
          width: 100%;
          text-align: center;
          color: #aaa;
        }
        &.add-btn {
          border: 1px dashed #e4e4e4;
          font-size: 40px;
          color: #e4e4e4;
          text-align: center;
          span {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 90px;
            line-height: 1;
            cursor: pointer;
          }
          input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
        &.active {
          border: 1px dashed #1890ff;
        }
      }
    }
    .add-row-btn {
      padding: 8px 0;
      border: 1px solid #ececed;
      text-align: center;
      color: #aaa;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .question-msg-title {
    margin-bottom: 15px;
    .label-del {
      margin-left: 15px;
      color: #b6b3b6;
      font-size: 12px;
    }
  }
}

/*
    *上传模板弹出框样式
    */
.label-input {
  margin-top: 5px;
  margin-bottom: 15px;
}
.template-upload-btn {
  position: relative;
  border-color: #1890ff;
  color: #1890ff;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.upload-suggestion {
  color: #a6a5a8;
  font-size: 12px;
}
.alert-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  button {
    width: 120px;
  }
}
.upload-btn {
  position: relative;
  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.ant-drawer-body {
  height: calc(~'100% - 55px');
}
/*
    *练习册详情
    */
.workBook-detail {
  width: 100%;
  .tree {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    height: 100%;
    .row {
      display: flex;
      align-items: center;
      margin: 20px 0;
      p {
        width: 70px;
        color: #abb0bf;
      }
      input {
        flex: 1;
      }
    }
  }
}
.crumbs {
  position: relative;
  height: 64px;
  padding-right: 30px;
  line-height: 64px;
  border-radius: 4px;
  background: #fff;
  text-align: center;
  color: #666;
  font-size: 14px;
  .return {
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    font-size: 18px;
    color: #9d9d9d;
    cursor: pointer;
  }
  .crumbs-icon {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 14px;
    color: #9d9d9d;
  }
  .crumbs-item {
    position: relative;
    cursor: pointer;
    span {
      position: absolute;
      bottom: -24px;
      left: 0;
      width: 100%;
      height: 2px;
    }
  }
}
</style>
