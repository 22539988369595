<template>
  <div class="HomeWork">
    <div style="padding-bottom: 55px;display: flex;height: 100%">
      <div style="flex:1;height: 100%;overflow-y: scroll;">
        <div style="position: relative">
          <div class="title-row">
            <span class="title-row-label">分类</span>
            <div class="title-row-input">
              <a-tree-select
                style="width: 100%;margin: 5px 0;"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="treeData2"
                placeholder="请选择分类"
                treeDefaultExpandAll
                v-model="createdClassify"
              >
                <span
                  style="color: #08c"
                  slot="title"
                  slot-scope="{ key, value }"
                  v-if="(key = '0-0-1')"
                  >{{ value }}</span
                >
              </a-tree-select>
              <!--<a-select defaultValue="lucy" style="width:100%;" v-model="createdClassify">-->
              <!--<a-select-option :value="item.Id" v-for="item in treeData2">{{item.title}}</a-select-option>-->
              <!--</a-select>-->
            </div>
          </div>

          <div class="title-row">
            <span class="title-row-label">学习进度</span>
            <div class="title-row-input">
              <a-select
                style="width:89px;margin-right: 15px;"
                v-model="progress[0]"
              >
                <a-select-option :value="index" v-for="(item, index) in 10">{{
                  index
                }}</a-select-option>
              </a-select>

              <a-select
                style="width:89px;margin-right: 15px;"
                v-model="progress[1]"
              >
                <a-select-option :value="index" v-for="(item, index) in 12">{{
                  index
                }}</a-select-option>
              </a-select>

              <a-select style="width:90px;" v-model="progress[2]">
                <a-select-option :value="item" v-for="item in 4">{{
                  item
                }}</a-select-option>
              </a-select>
            </div>
          </div>

          <div class="title-row">
            <span class="title-row-label">状态</span>
            <div class="title-row-input">
              <div
                style="display: inline-block;width: 50%;vertical-align: middle"
              >
                <a-checkbox
                  :checked="questionState == 1 ? true : false"
                  @click="questionState = 1"
                  >启用</a-checkbox
                >
              </div>
              <div
                style="display: inline-block;width: 50%;vertical-align: middle"
              >
                <a-checkbox
                  :checked="questionState == 0 ? true : false"
                  @click="questionState = 0"
                  >禁用</a-checkbox
                >
              </div>
            </div>
          </div>

          <div class="title-row">
            <span class="title-row-label">模板</span>
            <div class="title-row-input">
              <div class="select-input" @click="_seleTemplateShow">
                <span v-if="templateIndex == -1">请选择</span>
                <span v-else style="color: #666">{{
                  selectTemplate.Name
                }}</span>
              </div>
            </div>
          </div>

          <div class="title-row">
            <span class="title-row-label">题目</span>
            <div class="title-row-input">
              <a-input placeholder="请输入" v-model="createdName" />
            </div>
          </div>

          <div class="title-row">
            <span class="title-row-label">特性</span>
            <a-select
              mode="tags"
              style="width:800px"
              placeholder="请输入"
              @change="handleTagsChange"
              v-model="features"
            >
            </a-select>
          </div>

          <div class="title-row">
            <span class="title-row-label">附件</span>
            <div class="title-row-input">
              <a-button
                class="upload-attachment"
                @click="seleAccessory = !seleAccessory"
                >选择附件</a-button
              >
              <div v-if="materiaTemplateIndex != -1">
                <template v-for="(item, index) in materiaTemplateIndex">
                  <!--音频附件-->
                  <div
                    class="audio"
                    v-if="
                      item.FileType == 'audio/mp3' ||
                        item.FileType == 'audio/mpeg' ||
                        item.FileType == 'audio/x-aiff'
                    "
                    style="border: 1px solid #D9D9D9"
                  >
                    <div class="audio-file" style="margin-right: 10px">
                      <div style="padding: 10px 0">
                        <a-icon
                          type="customer-service"
                          style="font-size: 35px;color: #1890FF;"
                        />
                      </div>
                    </div>
                    <div class="audio-title">{{ item.FileName }}</div>
                    <i
                      class="iconfont icon-shanchu- icon-btn"
                      style="position: absolute;bottom: 10px;right: 10px;"
                      @click="_delAccessory(index)"
                    ></i>
                  </div>
                  <!--视频附件-->
                  <div
                    class="video"
                    v-else-if="
                      item.FileType == 'video/ogg' ||
                        item.FileType == 'video/mp4' ||
                        item.FileType == 'video/webm' ||
                        item.FileType == 'application/x-mpegURL'
                    "
                  >
                    <a-icon type="play-circle" class="play-btn" />
                    <img :src="item.FileUrl + '?vframe/jpg/offset/1'" alt="" />
                    <div class="video-msg flex">
                      <span class="video-name">{{ item.FileName }}</span>
                      <i
                        class="iconfont icon-shanchu- icon-btn"
                        style="margin-left: 5px"
                        @click="_delAccessory(index)"
                      ></i>
                    </div>
                  </div>
                  <!--图片附件-->
                  <div class="video" v-else>
                    <div style="height: 165px;position: relative">
                      <img
                        :src="item.FileUrl + '?imageView2/0/w/250'"
                        style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%)"
                        alt=""
                      />
                    </div>
                    <div class="video-msg flex" style="align-items: end">
                      <span class="video-name">{{ item.FileName }}</span>
                      <i
                        class="iconfont icon-shanchu- icon-btn"
                        style="margin-left: 5px"
                        @click="_delAccessory(index)"
                      ></i>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <!--模板缩略图-->
          <div class="template-thumbnail" v-if="selectTemplate">
            <ul>
              <li>
                <p>模板缩略图</p>
                <img
                  :src="selectTemplate.PicUrl + '?imageView2/1/w/85/h/105'"
                  alt=""
                />
              </li>

              <li>
                <p>扫码预览效果</p>
                <div id="qrcode"></div>
                <!--<img style="width: 100px;height: 100px" src="https://smart-resource.sikegroup.com/FhTq2AgMiH8pZ2T2dDiGc1Bg3EkH?imageView2/1/w/100/h/100" alt="">-->
              </li>
            </ul>
          </div>
        </div>
        <div class="question-msg">
          <div class="question-msg-title">
            <span class="label">数据项</span>
            <span class="label-del"
              >请按题干位置添加素材，占位格可为空（选中设置参数）</span
            >
          </div>
          <div
            class="title-row"
            v-for="(item, index) in questionList"
            style="align-items: end"
          >
            <div style="width: 80px;">
              <span
                class="title-row-label"
                style="width: 70px;text-align: center"
                >第{{ index + 1 }}行</span
              >
              <textarea
                v-model="item.Title"
                style="width:70px;height: 70px;border: 1px solid #ddd;border-radius: 4px;resize: none;margin-top:3px;padding:3px;outline: none"
              ></textarea>
            </div>
            <div class="title-row-input" style="width: 100%;">
              <ul>
                <li
                  v-for="(tab, tabIndex) in item.List"
                  @mouseover="_setParameter(index, tabIndex)"
                  @mouseout="setParameter = -1"
                  @click="_selectParameter(index, tabIndex)"
                  :class="
                    selectParameter == index + '-' + tabIndex ? 'active' : ''
                  "
                >
                  <span class="itme-empty" v-if="!tab.Image">选中设置</span>
                  <img
                    v-else
                    :src="tab.Image + '?imageView2/0/w/200'"
                    style="max-width:100%;max-height:100%;position: absolute;top:50%;left:50%;transform: translate(-50%,-50%)"
                    alt=""
                  />
                  <div
                    class="operation-btn"
                    v-show="setParameter == index + '-' + tabIndex"
                  >
                    <span style="border-right: 1px solid #fff" v-if="tab.Image">
                      <i class="iconfont icon-yulan"></i>
                      <viewer
                        style="height:36px;width:150px;margin-top:-32px;position:absolute;"
                      >
                        <img
                          :src="tab.Image"
                          style="width:100%;height:100%;opacity:0"
                        />
                      </viewer>
                    </span>
                    <span
                      style="border-right: 1px solid #fff"
                      @click.stop="_delQuestion(index, tabIndex)"
                      ><i class="iconfont icon-shanchu- icon-btn"></i
                    ></span>
                    <span @click.stop="_seleQImg(index, tabIndex)"
                      ><a-icon type="plus-circle" style="font-size: 17px"
                    /></span>
                  </div>
                </li>

                <li class="add-btn" @click="_addQuestion(index)">
                  <span>+</span>
                </li>
                <li style="border: 0;position: relative">
                  <a-icon
                    type="minus-circle"
                    @click="_delQuestionList(index)"
                    style="position: absolute;top:50%;left:0;transform: translateY(-50%);color: #FF4101;font-size: 20px"
                  />
                </li>
              </ul>
            </div>
          </div>

          <div class="title-row">
            <span class="title-row-label"></span>
            <div class="title-row-input" style="width: 400px">
              <div class="add-row-btn" @click="_addParameter">+ 添加行</div>
            </div>
          </div>
        </div>
      </div>

      <!--参数列表-->
      <div class="parameter" v-if="selectParameterIndex != -1">
        <div class="float">
          <div class="parameter-title">参数设置</div>
          <div class="item-row">
            <div class="row-label">数量（数字 例：1/时间 例：10:00）</div>
            <div class="row-input">
              <a-input
                placeholder="请输入"
                v-model="
                  questionList[selectParameterIndex].List[
                    selectParameterTableIndex
                  ].Config.Num
                "
              />
            </div>
          </div>

          <div class="item-row-checkbox">
            <a-checkbox
              :checked="
                questionList[selectParameterIndex].List[
                  selectParameterTableIndex
                ].Config.isAnswer == 1
                  ? true
                  : false
              "
              @change="_isAnswer"
              >设置为答案</a-checkbox
            >
          </div>

          <div class="item-row-checkbox">
            <a-checkbox
              :checked="
                questionList[selectParameterIndex].List[
                  selectParameterTableIndex
                ].Config.isThumb == 1
                  ? true
                  : false
              "
              @change="_isThumb"
              >作为此题缩略图</a-checkbox
            >
          </div>

          <div class="item-row-checkbox">
            <a-checkbox
              :checked="
                questionList[selectParameterIndex].List[
                  selectParameterTableIndex
                ].Config.isNull == 1
                  ? true
                  : false
              "
              @change="_isNull"
              >作为填空</a-checkbox
            >
          </div>

          <div class="item-row">
            <div class="row-input">
              <a-select defaultValue="请选择" style="width: 100%">
                <a-select-option value="0"
                  >数字选择（其他参数里面的数字，多个）</a-select-option
                >
                <a-select-option value="1"
                  >图片选择（数据项的图片）</a-select-option
                >
                <a-select-option value="2"
                  >图片选择（新的图片）</a-select-option
                >
              </a-select>
            </div>
          </div>

          <!--关联图片-->
          <div class="item-row" style="margin-bottom: 30px">
            <div class="row-label">关联图片</div>
            <div class="row-input">
              <div
                style="display: inline-block;position: relative;margin-right: 50px;"
                v-if="
                  questionList[selectParameterIndex].List[
                    selectParameterTableIndex
                  ].Config.RelevanceImage
                "
              >
                <div class="shade"></div>
                <i
                  class="iconfont icon-shanchu- icon-btn"
                  @click="
                    questionList[selectParameterIndex].List[
                      selectParameterTableIndex
                    ].Config.RelevanceImage = ''
                  "
                ></i>
                <img
                  :src="
                    questionList[selectParameterIndex].List[
                      selectParameterTableIndex
                    ].Config.RelevanceImage + '?imageView2/1/w/70/h/70'
                  "
                  class="association-img"
                  alt=""
                />
              </div>
              <div
                class="add-association"
                @click="
                  _addAssociation(
                    selectParameterIndex,
                    selectParameterTableIndex
                  )
                "
              >
                <p>+</p>
                <div>选择图片</div>
              </div>
            </div>
          </div>

          <div class="item-row-checkbox" style="margin-bottom: 10px;">
            <a-checkbox
              >可选参数
              <span style="font-size: 12px;color: #A5A4A7"
                >1.数字范围（1-10）， 2.循环次数N</span
              ></a-checkbox
            >
          </div>
          <div class="item-row">
            <div class="row-input">
              <a-input
                placeholder="请输入"
                v-model="
                  questionList[selectParameterIndex].List[
                    selectParameterTableIndex
                  ].Config.Optional
                "
              />
            </div>
          </div>
        </div>
      </div>

      <div class="alert-footer">
        <a-button style="margin-right:25px" @click="_titleClose">取消</a-button>
        <a-button type="primary" @click="_titleSub">确定</a-button>
      </div>
    </div>
    <!--上传模板弹出框-->
    <a-drawer
      :title="setTemplate != -1 ? '修改模板' : '上传模板'"
      placement="right"
      width="400"
      :closable="false"
      @close="_templateClose"
      :visible="templateVisible"
    >
      <div class="label">名称</div>
      <a-input
        class="label-input"
        placeholder="请输入"
        v-model="uploadTemplateName"
      />
      <div class="label">分类</div>
      <a-tree-select
        style="width: 100%;margin: 5px 0;"
        :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
        :treeData="treeData"
        placeholder="请选择分类"
        treeDefaultExpandAll
        v-model="uploadTemplateType"
      >
        <span
          style="color: #08c"
          slot="title"
          slot-scope="{ key, value }"
          v-if="(key = '0-0-1')"
          >{{ value }}</span
        >
      </a-tree-select>
      <!--<a-select defaultValue="请选择" class="label-input" style="width: 100%" v-model="uploadTemplateType">-->
      <!--<a-select-option :value="item.Id" v-for="item in treeData">{{item.title}}</a-select-option>-->
      <!--</a-select>-->
      <div class="label">唯一标识</div>
      <a-input
        class="label-input"
        placeholder="请输入"
        v-model="uploadTemplateLabel"
      />
      <div class="label">模板图片</div>
      <img
        v-if="uploadTemplatePic"
        :src="uploadTemplatePic.FileUrl + '?imageView2/1/w/352/h/200'"
        style="width: 100%;max-height: 200px;margin: 10px 0"
        alt=""
      />
      <a-button class="label-input template-upload-btn"
        >上传图片 <input type="file" @change="_uploadTemplatePic($event)"
      /></a-button>
      <div class="upload-suggestion">
        建议图片尺寸不大于1920*1080px，大小不超过2M
      </div>
      <div class="alert-footer">
        <a-button style="margin-right:25px" @click="_templateClose"
          >取消</a-button
        >
        <a-button type="primary" @click="_uploadTemplateSub">确定</a-button>
      </div>
    </a-drawer>
    <!--选择模板弹出框-->
    <a-drawer
      title="选择模板"
      placement="right"
      width="1080"
      :closable="false"
      @close="_seleTemplateClose"
      :visible="seleTemplate"
      :destroyOnClose="true"
    >
      <div class="HomeWork">
        <div class="container" style="margin-top:0">
          <div class="tree">
            <div class="tree-title">题型分类</div>
            <a-tree
              :treeData="treeData"
              defaultExpandAll
              @select="_treeSelect"
            />
          </div>
          <!--模板库-->
          <div class="content">
            <div class="row-title" style="padding-top: 0">
              <span>模板（{{ templateList.length }}）</span>
            </div>
            <div class="template-list">
              <ul class="title-row">
                <li v-for="(item, index) in templateList">
                  <div class="template-img">
                    <img :src="item.PicUrl" alt="" />
                  </div>
                  <div class="template-title flex">
                    <span v-text="item.Name"></span>
                    <a-radio
                      :checked="templateIndex == index ? true : false"
                      @change="_templateIndex(index)"
                    ></a-radio>
                  </div>
                  <div class="created-date" v-text="item.CreateTime"></div>
                </li>
              </ul>
              <div class="pagination" v-if="templateCount > 10">
                <a-pagination
                  :total="templateCount"
                  style="display: inline-block"
                  @change="_templatePage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="alert-footer">
        <a-button style="margin-right:25px" @click="_seleTemplateClose"
          >取消</a-button
        >
        <a-button type="primary" @click="seleTemplate = false">确定</a-button>
      </div>
    </a-drawer>
    <!--选择附件弹出框-->
    <!-- <a-drawer title="选择附件" placement="right" width="1080" :closable="false" @close="_seleAccessoryClose" :visible="seleAccessory">
            <material @fathermethod="_materialVisible"></material>
        </a-drawer> -->
    <a-drawer
      title="选择附件"
      placement="right"
      width="1080"
      :closable="false"
      @close="_seleAccessoryClose"
      :visible="seleAccessory"
    >
      <div class="condition" style="white-space: nowrap;margin-bottom: 15px;">
        <a-input
          v-model="searchMaterialName"
          style="width: 200px;margin-right: 15px;"
          placeholder="搜索名称"
        ></a-input>
        <a-button type="primary" @click="_searchMaterial">查询</a-button>
      </div>
      <div class="container" style="margin-top:0">
        <div class="tree">
          <div
            class="tree-title"
            style="cursor: pointer"
            @click="_treeSelectAccessory('')"
          >
            全部分类
          </div>
          <a-tree :treeData="materialList" @select="_treeSelectAccessory" />
        </div>
        <div class="content">
          <div class="row-title">
            <span>数量（{{ materiaData.count }}）</span>
            <a-button type="primary" class="upload-btn"
              >上传文件
              <input type="file" @change="_uploadAttachment($event)" multiple
            /></a-button>
          </div>
          <div class="template-list">
            <a-checkbox-group
              @change="_materiaIndex"
              :value="checkboxGroup"
              style="width: 100%"
            >
              <ul class="title-row">
                <li
                  v-for="(item, index) in materiaData.list"
                  @mouseover="_showOperation(index)"
                  @mouseout="showOperation = -1"
                >
                  <div
                    v-if="
                      item.FileType == 'audio/mpeg' ||
                        item.FileType == 'audio/x-aiff' ||
                        item.FileType == 'audio/mp3'
                    "
                  >
                    <div class="template-img" style="position: relative">
                      <a-icon
                        type="customer-service"
                        style="font-size: 60px;color: #1890FF;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%)"
                      />
                      <a
                        :href="item.FileUrl"
                        target="_blank"
                        style="display:block;position:absolute;left:0px;top:0px;width:100%;height:100%;"
                      ></a>
                    </div>

                    <div class="template-title flex">
                      <span v-text="item.FileName"></span>
                      <a-checkbox :value="index"></a-checkbox>
                    </div>
                    <div class="created-date flex">
                      <span>{{ item.CreateTime }}</span>
                      <a-dropdown
                        :placement="'topLeft'"
                        :getPopupContainer="
                          triggerNode => triggerNode.parentNode
                        "
                      >
                        <i
                          class="iconfont icon-shenglve"
                          style="font-size: 20px"
                        ></i>
                        <a-menu slot="overlay">
                          <a-menu-item
                            style="position:relative;overflow:hidden;"
                          >
                            <a
                              href="javascript:;"
                              @click="_openUrl(item.FileUrl)"
                              >预览</a
                            >
                          </a-menu-item>
                          <a-menu-item>
                            <a
                              href="javascript:;"
                              @click="
                                _downloadFile(
                                  item.FileUrl,
                                  item.FileName,
                                  item.FileType
                                )
                              "
                              >下載</a
                            >
                          </a-menu-item>
                          <a-menu-item>
                            <a
                              href="javascript:;"
                              @click="
                                _showRename(item.Id, item.FileName, index)
                              "
                              >重命名</a
                            >
                          </a-menu-item>
                          <a-menu-item>
                            <a-popconfirm
                              title="您确定要删除吗?"
                              @confirm="_delMaterial(index, item.Id)"
                              okText="确定"
                              cancelText="取消"
                            >
                              <a href="javascript:;">删除</a>
                            </a-popconfirm>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </div>
                  </div>
                  <!-- 视频 -->
                  <div
                    v-else-if="
                      item.FileType == 'video/ogg' ||
                        item.FileType == 'video/mp4' ||
                        item.FileType == 'video/webm' ||
                        item.FileType == 'application/x-mpegURL'
                    "
                  >
                    <div class="template-img">
                      <img
                        :src="
                          item.FileUrl +
                            '?vframe/jpg/offset/0|imageView2/1/w/218/h/140'
                        "
                        alt=""
                      />
                      <a
                        :href="item.FileUrl"
                        target="_blank"
                        style="display:block;position:absolute;left:0px;top:0px;width:100%;height:100%;"
                      ></a>
                    </div>
                    <div class="template-title flex">
                      <span v-text="item.FileName"></span>
                      <a-checkbox :value="index"></a-checkbox>
                    </div>
                    <div class="created-date flex">
                      <span>{{ item.CreateTime }}</span>
                      <a-dropdown
                        :placement="'topLeft'"
                        :getPopupContainer="
                          triggerNode => triggerNode.parentNode
                        "
                      >
                        <i
                          class="iconfont icon-shenglve"
                          style="font-size: 20px"
                        ></i>
                        <a-menu slot="overlay">
                          <a-menu-item
                            style="position:relative;overflow:hidden;"
                          >
                            <a
                              href="javascript:;"
                              @click="_openUrl(item.FileUrl)"
                              >预览</a
                            >
                          </a-menu-item>
                          <a-menu-item>
                            <a
                              href="javascript:;"
                              @click="
                                _downloadFile(
                                  item.FileUrl,
                                  item.FileName,
                                  item.FileType
                                )
                              "
                              >下載</a
                            >
                          </a-menu-item>
                          <a-menu-item>
                            <a
                              href="javascript:;"
                              @click="
                                _showRename(item.Id, item.FileName, index)
                              "
                              >重命名</a
                            >
                          </a-menu-item>
                          <a-menu-item>
                            <a-popconfirm
                              title="您确定要删除吗?"
                              @confirm="_delMaterial(index, item.Id)"
                              okText="确定"
                              cancelText="取消"
                            >
                              <a href="javascript:;">删除</a>
                            </a-popconfirm>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </div>
                  </div>
                  <!-- 图片 -->
                  <div v-else>
                    <div class="template-img">
                      <viewer style="height:100%;width:100%;position:absolute;">
                        <img :src="item.FileUrl" alt="" />
                      </viewer>
                    </div>
                    <div class="template-title flex">
                      <span v-text="item.FileName"></span>
                      <a-checkbox :value="index"></a-checkbox>
                    </div>
                    <div
                      style="position: absolute;top: 0px;right: 0px;"
                      class="created-date"
                      v-show="showOperation == index"
                    >
                      <div>
                        <a-dropdown
                          :placement="'bottomLeft'"
                          :getPopupContainer="
                            triggerNode => triggerNode.parentNode
                          "
                        >
                          <i
                            class="iconfont icon-shenglve"
                            style="font-size: 20px"
                          ></i>
                          <a-menu slot="overlay">
                            <a-menu-item
                              style="position:relative;overflow:hidden;"
                            >
                              <a href="javascript:;">预览</a>
                              <!-- <i class="iconfont icon-yulan"></i> -->
                              <viewer
                                style="height:36px;width:150px;margin-top:-32px;position:absolute;"
                              >
                                <img
                                  :src="
                                    item.FileUrl +
                                      '?imageMogr2/thumbnail/620x/strip/quality/50/format/webp'
                                  "
                                  style="width:100%;height:100%;opacity:0"
                                />
                              </viewer>
                            </a-menu-item>
                            <a-menu-item>
                              <a
                                href="javascript:;"
                                @click="
                                  _downloadFile(
                                    item.FileUrl,
                                    item.FileName,
                                    item.FileType
                                  )
                                "
                                >下載</a
                              >
                            </a-menu-item>
                            <a-menu-item>
                              <a
                                href="javascript:;"
                                @click="
                                  _showRename(item.Id, item.FileName, index)
                                "
                                >重命名</a
                              >
                            </a-menu-item>
                            <a-menu-item>
                              <a-popconfirm
                                title="您确定要删除吗?"
                                @confirm="_delMaterial(index, item.Id)"
                                okText="确定"
                                cancelText="取消"
                              >
                                <a href="javascript:;">删除</a>
                              </a-popconfirm>
                            </a-menu-item>
                          </a-menu>
                        </a-dropdown>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </a-checkbox-group>
            <div style="text-align: right" v-show="materiaData.count > 20">
              <a-pagination
                style="display: inline-block;"
                showSizeChanger
                @change="_onShowSizeChange"
                :defaultPageSize="20"
                :total="materiaData.count"
              />
            </div>
            <div
              v-if="materiaData.list.length == 0"
              style="font-size: 18px;color: #aaa;text-align: center"
            >
              暂无数据
            </div>
          </div>
        </div>
      </div>
      <div class="alert-footer">
        <a-button style="margin-right:25px" @click="_seleAccessoryClose"
          >取消</a-button
        >
        <a-button type="primary" @click="_seleAccessoryConfirm">确定</a-button>
      </div>
    </a-drawer>
    <!--重命名弹框-->
    <a-drawer
      title="重命名"
      placement="right"
      width="400"
      :closable="false"
      @close="_cloneRename"
      :visible="cloneRename"
    >
      <div class="container" style="margin-top:0">
        <a-input placeholder="请输入" v-model="Rename" style="width: 100%" />
      </div>
      <div class="alert-footer">
        <a-button style="margin-right:25px" @click="_cloneRename"
          >取消</a-button
        >
        <a-button type="primary" @click="_confirmRename">确定</a-button>
      </div>
    </a-drawer>
    <!--图片预览-->
    <viewer :images="viewerList" v-if="showViewer">
      <img v-for="(item, index) in viewerList" :key="index" :src="item" />
    </viewer>
    <!--练习题创建新节点-->
    <a-drawer
      title="节点设置"
      placement="right"
      width="400"
      :closable="false"
      @close="_newTreeNodeClose"
      :visible="TreeInput"
    >
      <div style="padding-bottom: 55px;height: 100%">
        <div class="container" style="margin-top: 0;justify-content: end">
          <span
            style="display: inline-block;width: 70px;vertical-align: middle;line-height: 32px"
            >节点名称</span
          >
          <a-input
            style="margin-right: 15px;width:220px;vertical-align: middle"
            v-model="addTreeInput"
          ></a-input>
        </div>

        <!--<div class="container" style="margin-top: 15px;justify-content: end;">-->
        <!--<span style="display: inline-block;width: 70px;vertical-align: middle;line-height: 32px">题型分类</span>-->
        <!--<a-tree-select style="width:220px;" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="treeData2" placeholder="请选择" @change="_nodeQuestion"></a-tree-select>-->
        <!--</div>-->

        <div
          class="container"
          style=";justify-content: end"
          v-show="addTreeInput"
        >
          <span
            style="display: inline-block;width: 70px;vertical-align: middle;line-height: 32px"
            >预览</span
          >
          <div id="alertQrCode" style="width: 100px;height: 100px;"></div>
        </div>
        <div class="alert-footer">
          <a-button style="margin-right:25px" @click="_newTreeNodeClose"
            >取消</a-button
          >
          <a-button type="primary" @click="_confirmAddTree">确定</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import workBook from 'pages/HomeWork/workBook'
import workTemplate from 'pages/HomeWork/workTemplate'
import material from 'pages/HomeWork/material'
const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
import QRCode from 'qrcodejs2'
import Vue from 'vue'
import Viewer from 'v-viewer' //图片操作
import 'viewerjs/dist/viewer.css'
import VueClipboard from 'vue-clipboard2' //复制
Vue.use(VueClipboard)
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source'
  }
})
export default {
  name: 'QuestionSetPage',
  components: { workBook, workTemplate, material },
  data () {
    return {
      tabsActive: 0,
      treeData: [],
      treeData2: [],
      workBookTreeData: '',
      searchClass: '',
      searchType: '',
      searchStatus: '',
      searcheSchedule_3: '',
      searcheSchedule_2: '',
      searcheSchedule_1: '',
      setTemplate: -1,
      selectedRowKeys: [],
      gldelbtn: false,
      tableData: [],
      AlertWorkBookTableTable: [],
      AlertWorkBookTableTableTotal: 1,
      templateList: [],
      templateCount: 10,
      tableDataCount: 10,
      tablePage: 1,
      templatePage: 1,
      materiaData: {
        count: 0,
        list: []
      }, //素材列表
      materialList: [], //素材分类
      viewerList: [
        'https://smart-resource.sikegroup.com/FpOfgo6FqKaS6VgjX5n-83Ny2kdx'
      ], //图片预览
      showViewer: false,
      templateVisible: false,
      titleVisible: false,
      seleTemplate: false,
      seleAccessory: false,
      container: '',
      setParameter: -1,
      selectParameter: -1,
      answer: false,
      detailData: '', //修改详情
      detailIndex: '',
      treeSelectAccessoryId: '', //选择附件tree选择ID
      seleQImgIndex: -1, //数据项选择附件
      seleQImgTabIndex: -1, //数据项选择附件
      addAssociationIndex: -1, //关联图片
      addAssociationTabIndex: -1, //关联图片2
      materiaTemplateIndex: [], //附件
      selectParameterIndex: -1, //选择的数据项
      selectParameterTableIndex: -1, //选择的数据项2
      checkboxGroup: [], //附件多选
      selectTemplateId: '', //选择模板分类ID

      uploadTemplateName: '', //模板名称
      uploadTemplateType: '', //模板分类
      uploadTemplateLabel: '', //唯一标识
      uploadTemplatePic: {}, //模板图片

      createdClassify: '', //分类
      progress: ['', '', ''], //学习进度
      selectTemplate: '', //模板
      templateIndex: -1,
      materiaIndex: -1, //附件Index
      createdName: '', //题目
      attachmentType: '', //附件类型
      questionList: [
        {
          Title: '',
          List: [
            // {
            //     Image:"",
            //     Config:{
            //         Num: '',
            //         isAnswer: 0,
            //         isThumb: 0,
            //         isNull: 0,
            //         RelevanceImage: "",
            //         Optional: ""
            //     }
            // }
          ]
        }
      ], //数据项

      cloneRename: false, //重命名显示隐藏
      Rename: '', //重命名
      RenameId: '', //重命名Id
      RenameIndex: '', //重命名下标

      showOperation: -1,
      workBookTableData: [], //练习册数据
      workBookDetailTableData: [], //练习册详情数据
      isNew: false, //是否新增
      setWorkBook: -1, //修改的下标
      workBookStatus: '', //查询状态
      workBookName: '', //查询名称
      workBookTableCount: 0, //练习册table条数
      workBookPage: 1, //练习册页码
      ageList: [], //年龄段数据
      ageBracket: [], //年龄段参数
      bookName: '', //名称
      bookIconUrl: '', //图片地址
      bookCourseDisplay: 1, //课程是否显示
      bookIsRec: 1, //是否推荐
      bookIntro: '', //课程介绍,
      bookCategoryData: [
        {
          Name: '练习大纲',
          Key: 'maxNode',
          Children: []
        }
      ],
      treeSelected: -1, //选择的节点下标
      TreeInput: false, //是否显示修改框
      addTree: -1, //要添加的节点下标
      setTree: -1, //要修改的节点名称
      addTreeInput: '', //tree添加的文字
      workBookVisible: false, //添加题目弹出框
      bookSelectedRowKeys: [],
      bookSelectedRow: [],
      downItem: '', //鼠标按下获取的对象
      downIndex: '', //鼠标按下获取的下标

      searchMaterialName: '', //素材搜索，
      homeBookClassify: '',
      homeBookClassifySelect: '',
      questionState: 1, //题型状态

      addQuDrawer: false,
      addQuDrawerCheck: [],
      qTitle: '',
      returnData: '',
      features: []
    }
  },
  props: ['QuestionId'],
  watch: {
    QuestionId: {
      immediate: true,
      handler (val) {
        console.log(val)
        this.init()
      },
      deep: true
    }
  },
  created () {
    //获取素材分类
    // this.$axios.get(1206,{label:'homeWorkMaterial'},res=>{
    //     if(res.data.code==1){
    //         this.materialList = res.data.data;
    //     }
    // });
    //获取练习册分类
    // this.$axios.get(1206,{label:'WorkBook'},res=>{
    //     if(res.data.code==1){
    //         this.homeBookClassify = res.data.data;
    //     }
    // });
    //获取模板分类
    // this.$axios.get(1206,{label:'HomeWorkTem'},res=>{
    //     if(res.data.code==1){
    //         this.treeData = res.data.data;
    //     }
    // });
    // //获取题库分类
    // this.$axios.get(1206,{label:'HomeWorkQuestion'},res=>{
    //     if(res.data.code==1){
    //         this.treeData2 = res.data.data;
    //     }
    // });
    //获取素材列表
    // this.$axios.get(5836125,{CategoryId:'',	limit: 20,	page: 1,},res=>{
    //     if(res.data.code==1){
    //         this.materiaData = res.data.data;
    //         console.log(this.materiaData)
    //     }
    // });
    //获取题库列表
    // this.$axios.get(5836126,{CategoryId:'',	State: '',Progress: '',	Type: '',Page: this.tablePage},res=>{
    //     if(res.data.code==1){
    //         this.tableData = res.data.data;
    //         this.tableDataCount = res.data.count;
    //     }
    // });
    //年龄段数据
    // let y = 9;
    // let m = 12;
    // let d = 4;
    // let self = this;
    // for(let i = 0; i < y; i++){
    //     self.age.push({value: i+1, label: i+1, children: []});
    //     for(let j = 0; j < m; j++){
    //         self.age[i].children.push({value: j+1, label: j+1, children: []});
    //         for(let k = 0; k < d;k++){
    //             self.age[i].children[j].children.push({value: k+1, label: k+1,});
    //         }
    //     }
    // }
    // for(let i = 0;i < 13;i++){
    //     self.ageList.push( {value: i,label: i ,children: []});
    //     for(let j = i+1;j < 13;j++){
    //         self.ageList[i].children.push({value: j ,label: j ,})
    //     }
    // }
    //获取模板列表
    // this._templateData();
    // this._setItem()
    // console.log(this.QuestionId)
  },
  mounted () {
    this.container = document.getElementsByClassName('HomeWork')[0]
  },
  methods: {
    init () {
      if (document.getElementById('qrcode')) {
        document.getElementById('qrcode').innerHTML = ''
      }
      //获取素材分类
      this._getCategoryIdList()

      //素材列表
      this._getMateriaList()

      //获取题库分类
      this.$axios.get(1206, { label: 'HomeWorkQuestion' }, res => {
        if (res.data.code == 1) {
          this.treeData2 = res.data.data
        }
      })
      //年龄段数据
      let y = 9
      let m = 12
      let d = 4
      let self = this

      this._setItem()
      console.log(this.QuestionId)
    },
    _addQuDrawerCheck (value) {
      this.addQuDrawerCheck = value
    },
    _addQuDrawerClose () {
      this.addQuDrawer = false
      this.addQuDrawerCheck = []
    },
    _configAddQuDrawer () {
      this.$axios.get(
        5836168,
        { category_id: this.addQuDrawerCheck.join(',') },
        res => {
          if (res.data.code == 1) {
            let arr = this.treeSelected.split('-')
            this.workBookDetailTableData = res.data.data
            this.workBookDetailTableData.map((res, index) => {
              res.sNumber = index + 1
              if (!res.QuestionId) {
                res.QuestionId = res.Id
              }
            })
            if (arr.length == 1) {
              this.bookCategoryData[0].Children[
                arr[0]
              ].QuestionData = this.workBookDetailTableData
              this.bookCategoryData[0].Children[arr[0]].qtype = 1
            } else if (arr.length == 2) {
              this.bookCategoryData[0].Children[arr[0]].Children[
                arr[1]
              ].QuestionData = this.workBookDetailTableData
              this.bookCategoryData[0].Children[arr[0]].Children[
                arr[1]
              ].qtype = 1
            } else if (arr.length == 3) {
              this.bookCategoryData[0].Children[arr[0]].Children[
                arr[1]
              ].Children[arr[2]].QuestionData = this.workBookDetailTableData
              this.bookCategoryData[0].Children[arr[0]].Children[
                arr[1]
              ].Children[arr[2]].qtype = 1
            }
            this.addQuDrawer = false
            this.addQuDrawerCheck = []
          } else {
          }
        }
      )
    },
    _nodeQuestion (value) {
      this.nodeQuestion = value
    },
    _workBookTablePage (page) {
      this.$axios.get(
        5836150,
        {
          Name: this.workBookName,
          CategoryId: this.homeBookClassifySelect,
          State: this.workBookStatus,
          Page: page
        },
        res => {
          if (res.data.code == 1) {
            this.workBookTableData = res.data.data
            this.workBookTableCount = res.data.count
          } else {
            this.workBookTableData = []
            this.workBookTableCount = 0
          }
        }
      )
    },
    _delQuestionList (i) {
      this.questionList.splice(i, 1)
    },
    _searchMaterial () {
      this.$axios.get(
        5836125,
        { CategoryId: '', limit: 20, page: 1, Name: this.searchMaterialName },
        res => {
          this.materiaData = res.data.data
          if (res.data.code == 0) {
            this.materiaData = { count: 0, list: [] }
          }
        }
      )
    },
    click (record, index) {
      let self = this
      return {
        on: {
          mousedown: () => {
            this.downItem = record
            this.downIndex = index
          },
          mouseup: () => {
            this.downItem.sNumber = index + 1
            record.sNumber = this.downIndex + 1
            this.$set(this.workBookDetailTableData, index, this.downItem)
            this.$set(this.workBookDetailTableData, this.downIndex, record)
            this.downItem = ''
            this.downIndex = ''
          }
        }
      }
    },
    _newTreeNodeClose () {
      this.TreeInput = false
    },
    _addQrCode (url, dom) {
      let self = this
      let qrcode = new QRCode(dom, {
        text: url,
        width: 60,
        height: 60,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    bookOnChange (selectedRowKeys, selectedRows) {
      //选中的数据的key
      this.bookSelectedRowKeys = selectedRowKeys
      this.bookSelectedRow = selectedRows
    },
    _showWorkBookVisible () {
      let self = this
      this.workBookVisible = true
      // this.tableData.map((res,index)=>{
      //     if(self.workBookDetailTableData){
      //         self.workBookDetailTableData.map((item)=>{
      //             if(item.QuestionId == res.Id){
      //                 self.bookSelectedRowKeys.push(index)
      //             }
      //         })
      //     }
      // });
    },
    _addQuestionData () {
      this.workBookVisible = false
      this.workBookDetailTableData = this.workBookDetailTableData.concat(
        this.bookSelectedRow
      )
      let arr = this.treeSelected.split('-')
      this.workBookDetailTableData.map((res, index) => {
        res.sNumber = index + 1
        if (!res.QuestionId) {
          res.QuestionId = res.Id
        }
      })
      if (arr.length == 1) {
        this.bookCategoryData[0].Children[
          arr[0]
        ].QuestionData = this.workBookDetailTableData
        this.bookCategoryData[0].Children[arr[0]].qtype = 0
      } else if (arr.length == 2) {
        this.bookCategoryData[0].Children[arr[0]].Children[
          arr[1]
        ].QuestionData = this.workBookDetailTableData
        this.bookCategoryData[0].Children[arr[0]].Children[arr[1]].qtype = 0
      } else if (arr.length == 3) {
        this.bookCategoryData[0].Children[arr[0]].Children[arr[1]].Children[
          arr[2]
        ].QuestionData = this.workBookDetailTableData
        this.bookCategoryData[0].Children[arr[0]].Children[arr[1]].Children[
          arr[2]
        ].qtype = 0
      }
      this.bookSelectedRow = []
      this.bookSelectedRowKeys = []
    },
    _delworkBookDetailTabl (i) {
      this.workBookDetailTableData.splice(i, 1)
    },
    _workBookClose () {
      this.workBookVisible = false
      this.bookSelectedRowKeys = []
    },
    _setBook () {
      let data = {
        Name: this.bookName,
        StartAge: this.ageBracket[0],
        EndAge: this.ageBracket[1],
        IconUrl: this.bookIconUrl,
        UserId: userInfo.uid,
        UserName: userInfo.name,
        CourseDisplay: this.bookCourseDisplay,
        isRec: this.bookIsRec,
        Intro: this.bookIntro,
        CategoryData: JSON.stringify(this.bookCategoryData),
        Id: this.workBookTableData[this.setWorkBook].Id,
        CategoryId: this.homeBookClassifySelect
      }
      this.$axios.post(5836154, data, res => {
        if (res.data.code == 1) {
          this.$message.success('修改成功')
          this.workBookTableData[this.setWorkBook].Name = this.bookName
          this.workBookTableData[this.setWorkBook].Progress =
            this.ageBracket[0] + '-' + this.ageBracket[1]
          this.workBookTableData[this.setWorkBook].CreateTime = '刚刚'
          this.workBookTableData[this.setWorkBook].IconUrl = this.bookIconUrl
          this.workBookTableData[this.setWorkBook].Intro = this.bookIntro
          this.workBookTableData[
            this.setWorkBook
          ].CourseDisplay = this.bookCourseDisplay
          this.workBookTableData[this.setWorkBook].isRec = this.bookIsRec
          this._return()
        }
      })
    },
    _bookSave () {
      let data = {
        Name: this.bookName,
        StartAge: this.ageBracket[0],
        EndAge: this.ageBracket[1],
        IconUrl: this.bookIconUrl,
        CreateUserId: userInfo.uid,
        CreateUserName: userInfo.name,
        CourseDisplay: this.bookCourseDisplay,
        isRec: this.bookIsRec,
        Intro: this.bookIntro,
        CategoryData: JSON.stringify(this.bookCategoryData),
        CategoryId: this.homeBookClassifySelect
      }
      this.$axios.post(5836152, data, res => {
        if (res.data.code == 1) {
          this.$message.success('添加成功')
          let item = {
            CourseDisplay: this.bookCourseDisplay,
            CreateTime: '刚刚',
            CreateUserId: userInfo.uid,
            CreateUserName: userInfo.name,
            IconUrl: this.bookIconUrl,
            Id: res.data.id,
            Intro: this.bookIntro,
            EndAge: this.ageBracket[1],
            Name: this.bookName,
            Progress: this.ageBracket[0] + '-' + this.ageBracket[1],
            State: this.bookCourseDisplay == 1 ? '启用' : '禁用',
            StartAge: this.ageBracket[0],
            isRec: this.bookIsRec,
            sNumber: 0
          }
          this.workBookTableData.push(item)
          this._return()
        }
      })
    },
    _reactData () {
      this.bookName = ''
      this.ageBracket = []
      this.bookIconUrl = ''
      this.bookCourseDisplay = 1
      this.bookIsRec = 1
      this.bookIntro = ''
      this.bookCategoryData = [
        { Name: '练习大纲', Key: 'maxNode', Children: [] }
      ]
      this.workBookDetailTableData = []
      this.treeSelected = -1
    },
    _delTree (index) {
      if (index.length == 3) {
        this.bookCategoryData[0].Children[index[0]].children[
          index[1]
        ].children.splice(index[2], 1)
      } else if (index.length == 2) {
        this.bookCategoryData[0].Children[index[0]].children.splice(index[1], 1)
      } else {
        this.bookCategoryData[0].Children.splice(index[0], 1)
      }
      this.bookCategoryData[0].Children.map((item_1, index) => {
        item_1.Key = index
        if (item_1.Children.length > 0) {
          item_1.Children.map((item_2, index_2) => {
            item_2.Key = index + '-' + index_2
            if (item_2.Children.length > 0) {
              item_2.Children.map((item_3, index_3) => {
                item_3.Key = index + '-' + index_2 + '-' + index_3
              })
            }
          })
        }
      })
    },
    _addTree (i) {
      this.addTree = i
      this.TreeInput = true
      this.addTreeInput = ''
    },
    _setTree (i, name, data) {
      this.addTreeInput = name
      this.setTree = i
      this.TreeInput = true
      setTimeout(function () {
        document.getElementById('alertQrCode').innerHTML = ''
        let qrcode = new QRCode('alertQrCode', {
          width: 100,
          height: 100,
          text:
            'https://q.qitianzhen.cn/homework/workBook?id=06197f3844285e5d5771968a305cbf3e&workBook=' +
            data.Id
        })
      })
    },
    _confirmAddTree () {
      if (this.addTreeInput == '') {
        return
      }
      if (this.setTree === -1) {
        let item = {
          Name: this.addTreeInput,
          Key: '',
          QuestionData: [],
          Children: []
        }
        if (this.addTree == '') {
          item.Key = String(this.bookCategoryData[0].Children.length)
          this.bookCategoryData[0].Children.push(item)
        } else if (this.addTree.length == 1) {
          item.Key =
            this.addTree[0] +
            '-' +
            this.bookCategoryData[0].Children[this.addTree[0]].Children.length
          this.bookCategoryData[0].Children[this.addTree[0]].Children.push(item)
        } else if (this.addTree.length == 2) {
          item.Key =
            this.addTree[0] +
            '-' +
            this.addTree[1] +
            '-' +
            this.bookCategoryData[0].Children[this.addTree[0]].Children[
              this.addTree[1]
            ].Children.length
          this.bookCategoryData[0].Children[this.addTree[0]].Children[
            this.addTree[1]
          ].Children.push(item)
        }
      } else {
        if (this.setTree.length == 1) {
          this.bookCategoryData[0].Children[
            this.setTree[0]
          ].Name = this.addTreeInput
        } else if (this.setTree.length == 2) {
          this.bookCategoryData[0].Children[this.setTree[0]].Children[
            this.setTree[1]
          ].Name = this.addTreeInput
        } else if (this.setTree.length == 3) {
          this.bookCategoryData[0].Children[this.setTree[0]].Children[
            this.setTree[1]
          ].Children[this.setTree[2]].Name = this.addTreeInput
        }
      }
      this.addTree = -1
      this.setTree = -1
      this.addTreeInput = ''
      this.TreeInput = false
    },
    _bookTreeSelect (selectedKeys, e) {
      this.workBookDetailTableData = []
      if (selectedKeys.length != 0) {
        this.treeSelected = selectedKeys.join('-')
      } else {
        this.treeSelected = -1
      }
      let arr = this.treeSelected.split('-')
      let id = ''
      if (arr.length == 1) {
        this.workBookDetailTableData = this.bookCategoryData[0].Children[
          arr[0]
        ].QuestionData
        id = this.bookCategoryData[0].Children[arr[0]].Id
      } else if (arr.length == 2) {
        this.workBookDetailTableData = this.bookCategoryData[0].Children[
          arr[0]
        ].Children[arr[1]].QuestionData
        id = this.bookCategoryData[0].Children[arr[0]].Children[arr[1]].Id
      } else if (arr.length == 3) {
        this.workBookDetailTableData = this.bookCategoryData[0].Children[
          arr[0]
        ].Children[arr[1]].Children[arr[2]].QuestionData
        id = this.bookCategoryData[0].Children[arr[0]].Children[arr[1]]
          .Children[arr[2]].Id
      }
      //如果问题是空的话去请求数据然后添加到数组里面
      if (!this.workBookDetailTableData) {
        this.$axios.get(
          5836155,
          {
            Page: 1,
            CategoryId: id,
            WorkBookId: this.workBookTableData[this.setWorkBook].Id
          },
          res => {
            if (res.data.code == 1) {
              this.workBookDetailTableData = res.data.data
              if (arr.length == 1) {
                this.bookCategoryData[0].Children[
                  arr[0]
                ].QuestionData = this.workBookDetailTableData
              } else if (arr.length == 2) {
                this.bookCategoryData[0].Children[arr[0]].Children[
                  arr[1]
                ].QuestionData = this.workBookDetailTableData
              } else if (arr.length == 3) {
                this.bookCategoryData[0].Children[arr[0]].Children[
                  arr[1]
                ].Children[arr[2]].QuestionData = this.workBookDetailTableData
              }
            }
          }
        )
      }
      let self = this
      //批量生成二维码
      this.workBookDetailTableData.map((res, index) => {
        setTimeout(function () {
          document.getElementById('qrcode-' + index).innerHTML = ''
          self._addQrCode(
            res.QrcodeUrl,
            document.getElementById('qrcode-' + index)
          )
        })
      })
    },
    _return () {
      this.setWorkBook = -1
      this.isNew = false
      this._reactData()
    },
    _delWorkBook (index) {
      this.$axios.post(
        5836151,
        { Id: this.workBookTableData[index].Id, UserId: userInfo.uid },
        res => {
          if (res.data.code == 1) {
            this.$message.success('删除成功')
            this.workBookTableData.splice(index, 1)
          }
        }
      )
    },
    _isNew () {
      this.isNew = true
    },
    _setWorkBook (i) {
      this.setWorkBook = i
      this.$axios.get(
        5836153,
        { Id: this.workBookTableData[i].Id, UserId: userInfo.uid },
        res => {
          if (res.data.code == 1) {
            let data = res.data.data
            this.bookName = data.WorkBookInfo.Name
            this.ageBracket = [
              data.WorkBookInfo.StartAge,
              data.WorkBookInfo.EndAge
            ]
            this.bookIconUrl = data.WorkBookInfo.IconUrl
            this.bookCourseDisplay = data.WorkBookInfo.CourseDisplay
            this.bookIsRec = data.WorkBookInfo.isRec
            this.bookIntro = data.WorkBookInfo.Intro
            this.bookCategoryData = data.CategoryInfo
            this.homeBookClassifySelect = data.WorkBookInfo.CategoryId
            // this.workBookDetailTableData = data.CategoryInfo[0];
            // console.log(this.workBookDetailTableData)
          }
        }
      )
    },
    _showOperation (i) {
      this.showOperation = i
    },
    _treeSelect2 (key) {
      this.tablePage = 1
      this.searchClass = key[0]
      this._searchData()
    },
    _treeSelect3 (key) {},
    _openUrl (url) {
      window.open(url)
    },
    _allData () {
      if (this.tabsActive == 1) {
        this.selectTemplateId = ''
        this._templateData()
      } else {
        this.searchClass = ''
        this._searchData()
      }
    },
    _showRename (id, name, i) {
      this.cloneRename = true
      this.Rename = name
      this.RenameId = id
      this.RenameIndex = i
    },
    _confirmRename () {
      if (this.Rename == '') {
        this.$message.error('名字不能为空！！')
        return false
      }
      this.$axios.post(
        5836138,
        { Id: this.RenameId, Name: this.Rename },
        res => {
          if (res.data.code == 1) {
            this.materiaData.list[this.RenameIndex].FileName = this.Rename
            this.$message.success(res.data.message)
            this._cloneRename()
          } else {
            this.$message.error(res.data.message)
          }
        }
      )
    },
    _cloneRename () {
      this.cloneRename = false
      this.Rename = ''
      this.RenameId = ''
      this.RenameIndex = ''
    },
    //素材库删除文件
    _delMaterial (i, id) {
      this.$axios.post(5836137, { Id: id }, res => {
        if (res.data.code == 1) {
          this.materiaData.list.splice(i, 1)
          this.materiaData.count = this.materiaData.count - 1
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    //下载附件
    _downloadFile (url, name, type) {
      let suffix = type.split('/')[1]
      //console.log(url+'?attname='+name+'.'+suffix)
      window.open(url + '?attname=' + name + '.' + suffix)
    },
    //删除附件
    _delAccessory (i) {
      this.materiaTemplateIndex.splice(i, 1)
    },
    _showViewer (url) {
      this.viewerList.push(url)
      this.showViewer = true
    },
    _onShowSizeChange (current) {
      this.$axios.get(
        5836125,
        { CategoryId: this.treeSelectAccessoryId, limit: 20, page: current },
        res => {
          if (res.data.code == 1) {
            this.materiaData = res.data.data
          }
        }
      )
    },
    _isAnswer (e) {
      e.target.checked == true
        ? (this.questionList[this.selectParameterIndex].List[
            this.selectParameterTableIndex
          ].Config.isAnswer = 1)
        : (this.questionList[this.selectParameterIndex].List[
            this.selectParameterTableIndex
          ].Config.isAnswer = 0)
    },
    _isThumb (e) {
      e.target.checked == true
        ? (this.questionList[this.selectParameterIndex].List[
            this.selectParameterTableIndex
          ].Config.isThumb = 1)
        : (this.questionList[this.selectParameterIndex].List[
            this.selectParameterTableIndex
          ].Config.isThumb = 0)
    },
    _isNull (e) {
      e.target.checked == true
        ? (this.questionList[this.selectParameterIndex].List[
            this.selectParameterTableIndex
          ].Config.isNull = 1)
        : (this.questionList[this.selectParameterIndex].List[
            this.selectParameterTableIndex
          ].Config.isNull = 0)
    },
    _tablePage (page) {
      this.tablePage = page
      this._searchData()
      this.bookSelectedRowKeys = []
    },
    _searchData () {
      let jindu = this.searcheSchedule_1
        ? this.searcheSchedule_1 +
          '-' +
          this.searcheSchedule_2 +
          '-' +
          this.searcheSchedule_3
        : ''
      this.$axios.get(
        5836126,
        {
          CategoryId: this.searchClass,
          State: this.searchStatus,
          Progress: jindu,
          Type: this.searchType,
          Page: this.tablePage,
          Year: this.searcheSchedule_1,
          Month: this.searcheSchedule_2,
          Week: this.searcheSchedule_3,
          Title: this.qTitle
        },
        res => {
          if (res.data.code == 1) {
            this.tableData = res.data.data
            this.tableDataCount = res.data.count
          } else {
            this.tableData = []
            this.tableDataCount = 0
          }
        }
      )
    },
    _materiaIndex (checkedValues) {
      let self = this
      this.materiaIndex = []
      this.checkboxGroup = checkedValues
      for (let j = 0; j < checkedValues.length; j++) {
        self.materiaIndex.push(self.materiaData.list[checkedValues[j]])
      }
    },
    _templateIndex (index) {
      this.templateIndex = index
      this.selectTemplate = this.templateList[index]
    },
    _seleAccessoryConfirm () {
      if (this.seleQImgIndex != -1) {
        this.questionList[this.seleQImgIndex].List[
          this.seleQImgTabIndex
        ].Image = this.materiaIndex[this.materiaIndex.length - 1].FileUrl
      } else if (this.addAssociationIndex != -1) {
        this.questionList[this.addAssociationIndex].List[
          this.addAssociationTabIndex
        ].Config.RelevanceImage = this.materiaIndex[
          this.materiaIndex.length - 1
        ].FileUrl
      } else {
        this.materiaTemplateIndex = this.materiaTemplateIndex.concat(
          this.materiaIndex
        )
      }
      this._seleAccessoryClose()
    },
    _seleAccessoryClose () {
      this.seleAccessory = false
      this.seleQImgIndex = -1
      this.seleQImgTabIndex = -1
      this.addAssociationIndex = -1
      this.addAssociationTabIndex = -1
      this.materiaIndex = -1
      this.checkboxGroup = []
    },
    _seleTemplateShow () {
      this.seleTemplate = true
      this._templateData()
      this.$axios.get(1206, { label: 'HomeWorkTem' }, res => {
        if (res.data.code == 1) {
          this.treeData = res.data.data
        }
      })
    },
    _seleTemplateClose () {
      this.seleTemplate = false
      this.templateIndex = -1
    },
    _uploadTemplatePic (e) {
      this.__uploadFile__(e.target.files).then(res => {
        this.uploadTemplatePic = res[0]
      })
    },
    _uploadCourceIcon (e) {
      this.__uploadFile__(e.target.files).then(res => {
        this.bookIconUrl = res[0].FileUrl
      })
    },
    _uploadAttachment (e) {
      this.$message.loading('上传中...')
      this.__uploadFile__(e.target.files, '/qiniu/homework_token').then(
        files => {
          let data = {
            UserId: userInfo.uid,
            UserName: userInfo.name,
            Data: JSON.stringify(files),
            CategoryId: this.treeSelectAccessoryId
          }
          this.$axios.post(5836124, data, res => {
            if (res.data.code == 1) {
              this.$message.success(res.data.message)
              this.$set(
                this.materiaData,
                'list',
                this.materiaData.list.concat(res.data.data)
              )
              this.$set(
                this.materiaData,
                'count',
                this.materiaData.count + res.data.data.length
              )
              this.$message.destroy()
            } else {
              this.$message.error(res.data.message)
            }
          })
        }
      )
    },
    //判断上传七牛的文件是什么类型
    _isFileType (type) {
      if (type == 'mp4' || type == 'rmvb' || type == 'avi' || type == 'ts') {
        this.attachmentType = 'video'
      } else if (type == 'mp3') {
        this.attachmentType = 'audio'
      } else {
        this.attachmentType = 'all'
      }
    },
    _selectParameter (index, tabIndex) {
      this.selectParameter = index + '-' + tabIndex
      this.selectParameterIndex = index
      this.selectParameterTableIndex = tabIndex
    },
    _addAssociation (index, tabIndex) {
      this.seleAccessory = true
      this.addAssociationIndex = index
      this.addAssociationTabIndex = tabIndex
    },
    _seleQImg (index, tabIndex) {
      this.seleAccessory = true
      this.seleQImgIndex = index
      this.seleQImgTabIndex = tabIndex
    },
    _delQuestion (index, tabIndex) {
      this.questionList[index].List.splice(tabIndex, 1)
      if (this.selectParameter == index + '-' + tabIndex) {
        this.selectParameterIndex = -1
        this.selectParameterTableIndex = -1
        this.selectParameter = -1
      }
    },
    _addQuestion (index) {
      let item = {
        Image: '',
        Config: {
          Num: '',
          isAnswer: 0,
          isThumb: 0,
          isNull: 0,
          RelevanceImage: '',
          Optional: ''
        }
      }
      this.questionList[index].List.push(item)
    },
    _addParameter () {
      this.questionList.push({ Title: '', List: [] })
    },
    _setParameter (index, tabIndex) {
      this.setParameter = index + '-' + tabIndex
    },
    _delTableRow (index) {
      this.$axios.post(
        5836128,
        { Id: this.tableData[index].Id, UserId: userInfo.uid },
        res => {
          if (res.data.code == 1) {
            this.$message.success('删除成功')
            this.tableData.splice(index, 1)
          }
        }
      )
    },
    _setItem () {
      this.$axios.get(5836133, { Id: this.QuestionId }, res => {
        if (res.data.code == 1) {
          this.detailData = res.data.data
          this.detailIndex = 0
          let item = this.detailData
          this.titleVisible = true
          this.createdClassify = item.CategoryId //分类
          this.progress = [item.Year, item.Month, item.Week] //学习进度
          this.selectTemplate = item.template_info //选择模板重置
          this.createdName = item.Title //题目
          this.materiaTemplateIndex = item.Enclosure //附件
          this.questionList = item.OptionData //数据项
          this.questionState = item.State
          this.features = item.features
          if (item.template_info) {
            setTimeout(function () {
              let qrcode = new QRCode('qrcode', {
                width: 100,
                height: 100, // 高度
                text: 'https://q.qitianzhen.cn/homework/workBook?id=' + item.Id
                //text: 'https://q.qitianzhen.cn/homework/'+item.template_info.Label+'?id='+item.Id // 二维码内容
              })
              console.log(
                'https://q.qitianzhen.cn/homework/workBook?id=' + item.Id
              )
              // console.log('https://hwork.sikegroup.com/'+item.template_info.Label+'?id='+item.Id)
            })
          }
        }
      })
    },
    _titleSub () {
      let data = {
        Title: this.createdName,
        TemplateId: this.selectTemplate ? this.selectTemplate.Id : '',
        CategoryId: this.createdClassify,
        Year: this.progress[0],
        Month: this.progress[1],
        Week: this.progress[2],
        CreateUserId: userInfo.uid,
        CreateUserName: userInfo.name,
        Enclosure: JSON.stringify(this.materiaTemplateIndex),
        OptionData: JSON.stringify(this.questionList),
        Answer: '', //答案
        State: this.questionState,
        features: JSON.stringify(this.features)
      }
      let item = {
        Title: this.createdName,
        Type: '',
        CategoryName: this.createdClassify,
        QrcodeUrl: '',
        CreateUserId: userInfo.uid,
        CreateUserName: userInfo.name,
        Id: '',
        FitAge: '', //适合的年龄
        State: '启用',
        CreateTime: new Date().toLocaleDateString(),
        Progress:
          this.progress[0] + '-' + this.progress[1] + '-' + this.progress[2],
        sNumber: this.tableData.length - 1
      }
      if (this.detailData) {
        data.Id = this.detailData.Id
        item.Id = this.detailData.Id
        this.$axios.post(5836134, data, res => {
          if (res.data.code == 1) {
            this.$message.success('修改成功')
            this.tableData[this.detailIndex] = item
            this.returnData = data
            this._titleClose()
          } else {
            this.$message.error(res.data.message)
          }
        })
      } else {
        this.$axios.post(5836129, data, res => {
          if (res.data.code == 1) {
            this.$message.success('添加成功')
            item.Id = res.data.id
            this.tableData.push(item)
            this._titleClose()
          } else {
            this.$message.error(res.data.message)
          }
        })
      }
    },
    _titleClose () {
      this.titleVisible = false
      this.seleAccessory = false
      this.createdClassify = '' //分类
      this.progress = ['', '', ''] //学习进度
      this.templateIndex = -1 //选择模板重置
      this.createdName = '' //题目
      this.materiaTemplateIndex = [] //附件
      this.attachmentType = '' //附件类型
      this.questionList = [
        {
          Title: '',
          List: [
            // {
            //     Image:"",
            //     Config:{
            //         Num: '',
            //         isAnswer: 0,
            //         isThumb: 0,
            //         isNull: 0,
            //         RelevanceImage: "",
            //         Optional: ""
            //     }
            // }
          ]
        }
      ] //数据项
      this.selectParameter = -1
      this.selectParameterIndex = -1
      this.selectParameterTableIndex = -1
      this.detailData = ''
      if (document.getElementById('qrcode')) {
        document.getElementById('qrcode').innerHTML = ''
      }
      this.selectTemplate = ''
      this.State = 1
      this.$emit('fathermethod', this.returnData)
    },
    _uploadTemplateSub () {
      let data = {
        Name: this.uploadTemplateName,
        Label: this.uploadTemplateLabel,
        CategoryId: this.uploadTemplateType,
        PicUrl: this.uploadTemplatePic.FileUrl,
        CreateUserId: userInfo.uid,
        CreateUserName: userInfo.name
      }
      if (this.setTemplate != -1) {
        data.Id = this.templateList[this.setTemplate].Id
        data.UserId = userInfo.uid
        this.$axios.post(5836132, data, res => {
          if (res.data.code == 1) {
            this.$message.success('修改成功')
            let item = {
              CategoryId: this.uploadTemplateType,
              CreateTime: new Date().toLocaleDateString(),
              CreateUserId: userInfo.uid,
              CreateUserName: userInfo.name,
              Id: data.Id,
              Label: this.uploadTemplateLabel,
              Name: this.uploadTemplateName,
              PicUrl: this.uploadTemplatePic.FileUrl
            }
            this.templateList[this.setTemplate] = item
            this._templateClose()
          } else {
            this.$message.success(res.data.message)
          }
        })
      } else {
        this.$axios.post(5836130, data, res => {
          if (res.data.code == 1) {
            this.$message.success('添加成功')
            let item = {
              CategoryId: this.uploadTemplateType,
              CreateTime: new Date().toLocaleDateString(),
              CreateUserId: userInfo.uid,
              CreateUserName: userInfo.name,
              Id: res.data.Id,
              Label: this.uploadTemplateLabel,
              Name: this.uploadTemplateName,
              PicUrl: this.uploadTemplatePic.FileUrl
            }
            this.templateList.push(item)
            this._templateClose()
          } else {
            this.$message.success(res.data.message)
          }
        })
      }
    },
    _templateClose () {
      this.templateVisible = false
      this.uploadTemplateName = '' //模板名称
      this.uploadTemplateType = '' //模板分类
      this.uploadTemplateLabel = '' //唯一标识
      this.uploadTemplatePic = '' //模板图片
      this.setTemplate = -1 //判断是否是修改模板
    },
    _homeBookClassify (key) {
      this.homeBookClassifySelect = key[0]
      this._workBookInfo()
    },
    _treeSelectAccessory (key) {
      this.treeSelectAccessoryId = key[0]
      this.materiaData = { count: 0, list: [] }
      this.$axios.get(
        5836125,
        { CategoryId: this.treeSelectAccessoryId, limit: 20, page: 1 },
        res => {
          if (res.data.code == 0) {
            this.materiaData.count = 0
            this.materiaData.list = []
          } else {
            this.materiaData = res.data.data
          }
        }
      )
    },
    _treeSelect (key, e) {
      this.selectTemplateId = key[0]
      this._templateData()
    },
    _setTemplate (index) {
      this.uploadTemplateName = this.templateList[index].Name //模板名称
      this.uploadTemplateType = this.templateList[index].CategoryId //模板分类
      this.uploadTemplateLabel = this.templateList[index].Label //唯一标识
      this.uploadTemplatePic = { FileUrl: this.templateList[index].PicUrl } //模板图片
      this.templateVisible = true
      this.setTemplate = index
    },
    _delTemplate (index) {
      this.$axios.post(
        5836131,
        { Id: this.templateList[index].Id, UserId: userInfo.uid },
        res => {
          if (res.data.code == 1) {
            this.templateList.splice(index, 1)
            this.$message.success('删除成功')
          }
        }
      )
    },
    _templatePage (page) {
      this.templatePage = page
      this._templateData()
    },
    _templateData () {
      this.$axios.get(
        5836127,
        { CategoryId: this.selectTemplateId, Page: this.templatePage },
        res => {
          if (res.data.code == 1) {
            this.templateList = res.data.data
            this.templateCount = res.data.count
          } else {
            this.templateList = []
            this.templateCount = 0
          }
        }
      )
    },
    _tabsActive (index) {
      this.tabsActive = index
      this.selectTemplateId = ''
      this.setWorkBook = -1
      this.isNew = false
      if (index == 2) {
        this._workBookInfo()
      }
    },
    _workBookInfo (page) {
      this.workBookPage = 1
      this.workBookName = ''
      this.workBookStatus = ''
      this.$axios.get(
        5836150,
        {
          Name: this.workBookName,
          CategoryId: this.homeBookClassifySelect,
          State: this.workBookStatus,
          Page: 1
        },
        res => {
          if (res.data.code == 1) {
            this.workBookTableData = res.data.data
            this.workBookTableCount = res.data.count
          } else {
            this.workBookTableData = []
            this.workBookTableCount = 0
          }
        }
      )
    },
    _workBookTableInfo (page) {
      this.$axios.get(
        5836150,
        {
          Name: this.workBookName,
          State: this.workBookStatus,
          Page: page ? page : this.workBookPage
        },
        res => {
          if (res.data.code == 1) {
            this.workBookTableData = res.data.data
            this.workBookTableCount = res.data.count
            this.workBookPage += 1
          } else {
            this.workBookTableData = []
            this.workBookTableCount = 0
          }
        }
      )
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    _copy (index) {
      let Id = this.tableData[index].Id
      let self = this
      this.$axios.post(
        7000002,
        { Id: Id, UserId: userInfo.uid, UserName: userInfo.name },
        res => {
          if (res.data.code == 1) {
            this.$message.success(res.data.message)
            self.tableData = []
            self.tableDataCount = 0
            //获取题库列表
            this.$axios.get(
              5836126,
              {
                CategoryId: '',
                State: '',
                Progress: '',
                Type: '',
                Page: self.tablePage
              },
              res => {
                if (res.data.code == 1) {
                  self.tableData = res.data.data
                  self.tableDataCount = res.data.count
                }
              }
            )
          } else {
            this.$message.error(res.data.message)
          }
        }
      )
    },
    _materialVisible (data) {
      this.seleAccessory = false
      if (data) {
        // this.materiaTemplateIndex = data;
        this._seleAccessoryConfirm()
      }
    },
    _getCategoryIdList () {
      // this.$message.loading('加载中...');
      this.$axios.get(1206, { label: 'homeWorkMaterial' }, res => {
        if (res.data.code == 1) {
          // this.$message.destroy();
          this.materialList = res.data.data
        }
      })
    },
    _getMateriaList () {
      //获取素材列表
      // this.$message.loading('加载中...');
      this.$axios.get(5836125, { CategoryId: '', limit: 20, page: 1 }, res => {
        if (res.data.code == 1) {
          this.materiaData = res.data.data
          // this.$message.destroy();
        }
      })
    },
    handleTagsChange (value) {
      this.features = value
    }
  }
}
</script>

<style lang="less" scoped>
.HomeWork {
  height: 100%;
  .tabs {
    border-bottom: 1px solid #ccd1dc;
    border-left: 1px solid #ccd1dc;
    border-top-left-radius: 5px;
    ul {
      li {
        display: inline-block;
        width: 85px;
        padding: 6px 0;
        text-align: center;
        border: 1px solid #ccd1dc;
        border-bottom: none;
        cursor: pointer;
        border-left: 0;
        &:first-child {
          border-top-left-radius: 5px;
        }
        &:last-child {
          border-top-left-radius: 0;
          border-top-right-radius: 5px;
        }
      }
      .active {
        background: #1890ff;
        color: #fff;
        border: 1px solid #1890ff;
      }
    }
  }
}
.container {
  min-height: calc(~'100% - 60px');
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .content {
    flex: 1;
    margin-left: 15px;
    padding: 15px;
    padding-bottom: 40px;
    border: 1px solid #dfe0e6;
    background: #fff;
    border-radius: 5px;
    .row-title {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      span {
        font-size: 16px;
      }
    }

    .template-list {
      padding-top: 15px;
      .search-div {
        .condition {
          display: inline-block;
          margin-right: 20px;
          margin-bottom: 10px;
          span {
            margin-right: 10px;
          }
        }
      }
      .icon-btn {
        font-size: 20px;
        margin-right: 8px;
        cursor: pointer;
      }

      .title-row {
        display: flex;
        flex-wrap: wrap;
        > li {
          position: relative;
          width: 140px;
          margin-right: 10px;
          margin-bottom: 15px;
          .template-img {
            height: 100px;
            overflow: hidden;
            position: relative;
          }
          .template-title {
            padding: 15px;
            padding-bottom: 0;
            font-size: 16px;
            color: #2d3e5d;
            span {
              display: inline-block;
              width: 180px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .created-date {
            padding: 0 15px;
            padding-bottom: 10px;
            color: #999;
          }
          img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .pagination {
        text-align: right;
      }
    }
    .template-library {
      .title-row {
        > li {
          width: 200px;
          margin-right: 15px;
          margin-bottom: 15px;
          .template-img {
            height: 160px;
          }
        }
      }
    }
  }
  .tree {
    width: 200px;
    padding: 15px;
    border: 1px solid #dfe0e6;
    border-radius: 5px;
    background: #fff;
    .tree-title {
    }
  }
}
.status-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ddd;
  margin-right: 3px;
}
/*
    *添加题目模板弹出框样式
    */
.parameter {
  position: relative;
  width: 350px;
  padding: 0 20px;
  border-left: 8px solid #f9f9fb;
  .float {
    position: fixed;
  }
  .parameter-title {
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin-bottom: 15px;
    background: #666668;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    font-size: 14px;
  }
  .item-row {
    margin-bottom: 15px;
    .row-label {
      margin-bottom: 10px;
    }
    .row-input {
      position: relative;
      .association-img {
        width: 70px;
        height: 70px;
      }
      .shade {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.7;
      }
      .icon-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        color: #fff;
      }
      .add-association {
        display: inline-block;
        vertical-align: top;
        width: 70px;
        height: 70px;
        border: 1px dashed #e3e2e5;
        text-align: center;
        cursor: pointer;
        position: relative;
        p {
          margin-top: -15px;
          font-size: 50px;
          color: #aaa;
          font-weight: 300;
        }
        div {
          margin-top: -15px;
          font-size: 12px;
          color: #aaa;
        }
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }
  }
  .item-row-checkbox {
    margin-bottom: 20px;
  }
}
.title-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .video {
    position: relative;
    border: 1px solid #eee;
    .video-msg {
      padding: 10px;
      border-top: 1px solid #f3f1f3;
      .video-name {
        font-size: 16px;
      }
      .video-length {
        color: #aaa;
        font-size: 14px;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;
      max-height: 165px;
    }
    .play-btn {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      color: #666;
      cursor: pointer;
    }
  }
  .audio {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    .audio-title {
      position: relative;
      flex: 1;
      .audio-upload-time {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: 12px;
        color: #aaa;
      }
    }
    .audio-file {
      width: 80px;
      padding: 10px 0;
      background: #fafafc;
      text-align: center;
      margin-left: 10px;
    }
    .audio-length {
      color: #aaa;
      font-size: 14px;
    }
  }
  .title-row-label {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
  }
  .title-row-input {
    display: inline-block;
    vertical-align: middle;
    width: 300px;
    .select-input {
      width: 100%;
      height: 30px;
      padding: 0 10px;
      line-height: 30px;
      background: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
      span {
        color: #ddd;
      }
    }
    .upload-attachment {
      position: relative;
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
    .audio,
    .video {
      margin-top: 15px;
    }
  }
}
.template-thumbnail {
  position: absolute;
  top: 15px;
  right: 15px;
  ul {
    li {
      display: inline-block;
      width: 150px;
      height: 150px;
      margin-right: 20px;
      border: 1px solid #e4e3ea;
      border-radius: 5px;
      text-align: center;
      vertical-align: top;
      p {
        margin-top: 5px;
        text-align: center;
        color: #666;
        font-size: 12px;
      }
      img {
        display: inline-block !important;
        margin: 10px 20px;
        width: 85px;
        height: 105px;
      }
      #qrcode {
        margin: 0 auto;
        margin-top: 15px;
        width: 90px;
        height: 90px;
        img {
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.question-msg {
  .title-row-input {
    ul {
      li {
        position: relative;
        display: inline-block;
        width: 100px;
        height: 100px;
        margin-right: 15px;
        margin-bottom: 15px;
        border: 1px solid #e4e4e4;
        vertical-align: top;
        .operation-btn {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: #000;
          opacity: 0.7;
          color: #fff;
          display: flex;
          align-items: center;
          span {
            position: relative;
            display: inline-block;
            flex: 1;
            padding: 4px 0;
            text-align: center;
            font-size: 12px;
            cursor: pointer;
            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
          }
        }
        .itme-empty {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
          width: 100%;
          text-align: center;
          color: #aaa;
        }
        &.add-btn {
          border: 1px dashed #e4e4e4;
          font-size: 40px;
          color: #e4e4e4;
          text-align: center;
          span {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 90px;
            line-height: 1;
            cursor: pointer;
          }
          input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
        &.active {
          border: 1px dashed #1890ff;
        }
      }
    }
    .add-row-btn {
      padding: 8px 0;
      border: 1px solid #ececed;
      text-align: center;
      color: #aaa;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .question-msg-title {
    margin-bottom: 15px;
    .label-del {
      margin-left: 15px;
      color: #b6b3b6;
      font-size: 12px;
    }
  }
}

/*
    *上传模板弹出框样式
    */
.label-input {
  margin-top: 5px;
  margin-bottom: 15px;
}
.template-upload-btn {
  position: relative;
  border-color: #1890ff;
  color: #1890ff;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.upload-suggestion {
  color: #a6a5a8;
  font-size: 12px;
}
.alert-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  button {
    width: 120px;
  }
}
.upload-btn {
  position: relative;
  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.ant-drawer-body {
  height: calc(~'100% - 55px');
}
/*
    *练习册详情
    */
.workBook-detail {
  width: 100%;
  .tree {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    height: 100%;
    .row {
      display: flex;
      align-items: center;
      margin: 20px 0;
      p {
        width: 70px;
        color: #abb0bf;
      }
      input {
        flex: 1;
      }
    }
  }
}
.crumbs {
  position: relative;
  height: 64px;
  padding-right: 30px;
  line-height: 64px;
  border-radius: 4px;
  background: #fff;
  text-align: center;
  color: #666;
  font-size: 14px;
  .return {
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    font-size: 18px;
    color: #9d9d9d;
    cursor: pointer;
  }
  .crumbs-icon {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 14px;
    color: #9d9d9d;
  }
  .crumbs-item {
    position: relative;
    cursor: pointer;
    span {
      position: absolute;
      bottom: -24px;
      left: 0;
      width: 100%;
      height: 2px;
    }
  }
}
</style>
