<template>
    <div class="workBook">
        <div class="container">
            <div class="tree">
                <div v-if="homeBookClassify">
                    <a-tree
                            :treeData="homeBookClassify"
                            defaultExpandAll
                            @select="_homeBookClassify"
                    />
                </div>
            </div>

            <div v-if="!isNew" class="content">
                <div class="template-list flex" style="padding-top:.15rem">
                    <div class="search-div">
                        <div class="condition">
                            <span>状态</span>
                            <a-select style="width: 140px" v-model="workBookStatus">
                                <a-select-option value="">全部</a-select-option>
                                <a-select-option value="1">启用</a-select-option>
                                <a-select-option value="0">禁用</a-select-option>
                            </a-select>
                        </div>

                        <div class="condition">
                            <span>名称</span>
                            <a-input style="width: 140px" v-model="workBookName"></a-input>
                        </div>

                        <a-button type="primary" @click="_workBookInfo(1)">查询</a-button>
                    </div>
                    <a-button type="primary" style="width: 100px" @click="_showNewPage"
                    >新建
                    </a-button
                    >
                </div>

                <div style="margin-top: 20px;margin-right: 20px;">
                    <a-table
                            bordered
                            :columns="workBookTableColumns"
                            :dataSource="workBookTableData"
                            :pagination="false"
                    >
                        <div slot="status" slot-scope="text, record, index">
              <span
                      class="status-icon"
                      :style="
                  text == '禁用' ? 'background: #E81B1C' : 'background: #4199F3'
                "
              ></span>
                            {{ text }}
                        </div>
                        <div slot="icon" slot-scope="text, record, index">
                            <img :src="text" style="width: 40px" alt=""/>
                        </div>
                        <div slot="operation" slot-scope="text, record, index">
                            <!-- <a-popconfirm title="你确定要复制嘛?" okText="确定" @confirm="_copyWorkBook(index)" cancelText="取消">
                                          <a-icon style="font-size: 18px;margin-right: 5px;"  class="icon-btn" type="copy" />
                                          </a-popconfirm> -->
                            <i
                                    class="iconfont icon-bianji icon-btn"
                                    style="font-size: 18px;margin-right: 5px;"
                                    @click="_setWorkBook(index)"
                            ></i>
                            <a-popconfirm
                                    title="您确定要删除吗?"
                                    okText="确定"
                                    @confirm="_delWorkBook(index)"
                                    cancelText="取消"
                            >
                                <i class="iconfont icon-shanchu- icon-btn"></i>
                            </a-popconfirm>
                        </div>
                    </a-table>
                    <div class="pagination" v-if="workBookTableCount > 10">
                        <a-pagination
                                :total="workBookTableCount"
                                style="display: inline-block;margin-top:20px;"
                                @change="_workBookInfo"
                        />
                    </div>
                </div>
            </div>

            <!--练习册详情-->
            <div v-if="isNew" style="flex:1;margin-left: 15px;min-height: 100%">
                <div class="crumbs" style="margin-bottom: 15px;">
                    <a-icon class="return" @click="_return" type="left"/>
                    <div
                            style="position: absolute;top:0; right: 25px;height:100%"
                            v-if="setWorkBook == -1"
                            @click="_bookSave"
                    >
                        <a-button type="primary">保存</a-button>
                    </div>
                    <div
                            style="position: absolute;top:0; right: 25px;height:100%"
                            v-else
                            @click="_setBook"
                    >
                        <a-button type="primary">修改</a-button>
                    </div>
                </div>
                <div class="workBook-detail flex">
                    <!--基本信息-->
                    <div class="tree" style="width: 300px;">
                        <div class="tree-title">基本信息</div>
                        <div class="row">
                            <p>名称</p>
                            <a-input placeholder="请输入" v-model="bookName"></a-input>
                        </div>

                        <div class="row">
                            <p>课程图标</p>
                            <a-button style="position: relative"
                            >上传图片
                                <input
                                        type="file"
                                        style="position: absolute;width: 100%;height: 100%;opacity: 0;top:0;left:0"
                                        @change="_uploadCourceIcon($event)"
                                /></a-button>
                        </div>

                        <div class="row" v-if="bookIconUrl">
                            <p></p>
                            <img :src="bookIconUrl + '?imageView2/0/w/60'" alt=""/>
                        </div>

                        <div class="row">
                            <p>年龄段</p>
                            <a-cascader
                                    :options="ageList"
                                    v-model="ageBracket"
                                    placeholder="请选择"
                            />
                        </div>

                        <div class="row">
                            <p>课程显示</p>
                            <div style="flex: 1">
                                <div
                                        style="display: inline-block;width: 50%;vertical-align: middle"
                                >
                                    <a-checkbox
                                            :checked="bookCourseDisplay == 1 ? true : false"
                                            @click="bookCourseDisplay = 1"
                                    >启用
                                    </a-checkbox
                                    >
                                </div>
                                <div
                                        style="display: inline-block;width: 50%;vertical-align: middle"
                                >
                                    <a-checkbox
                                            :checked="bookCourseDisplay == 0 ? true : false"
                                            @click="bookCourseDisplay = 0"
                                    >禁用
                                    </a-checkbox
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <p>是否推荐</p>
                            <div style="flex: 1">
                                <div
                                        style="display: inline-block;width: 50%;vertical-align: middle"
                                >
                                    <a-checkbox
                                            :checked="bookIsRec == 1 ? true : false"
                                            @click="bookIsRec = 1"
                                    >是
                                    </a-checkbox
                                    >
                                </div>
                                <div
                                        style="display: inline-block;width: 50%;vertical-align: middle"
                                >
                                    <a-checkbox
                                            :checked="bookIsRec == 0 ? true : false"
                                            @click="bookIsRec = 0"
                                    >否
                                    </a-checkbox
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="row" style="align-items: end">
                            <p>课程介绍</p>
                            <textarea
                                    v-model="bookIntro"
                                    style="flex:1;height: 150px;border:1px solid #CCD1DC;border-radius: 4px;resize: none;padding: 5px;"
                            ></textarea>
                        </div>

                        <div class="row" style="align-items: end">
                            <p>展示样式</p>
                            <a-checkbox :checked="showStyleCheck == 0 ? true : false" @click="showStyleCheck = 0">竖版</a-checkbox>
                            <a-checkbox :checked="showStyleCheck == 1 ? true : false" @click="showStyleCheck = 1">横版</a-checkbox>
                        </div>

                    </div>
                    <!--题目大纲-->
                    <div class="tree" style="width: 250px">
                        <div class="tree-title flex">
              <span
                      @click.stop="
                  _setTree([-1], bookCategoryData[0].Name, bookCategoryData[0])
                "
              >练习大纲</span
              >
                            <a-icon
                                    type="plus-circle"
                                    style="font-size: 16px;margin-left:3px;color:rgba(0, 0, 0, 0.65);cursor: pointer"
                                    @click="_addTree('')"
                            />
                        </div>
                        <a-tree defaultExpandAll @select="_getQuestionData">
                            <template v-for="(item, index) in bookCategoryData[0].Children">
                                <a-icon slot="icon" type="carry-out"/>
                                <a-tree-node :key="item.Key">
                                    <a-icon slot="icon" type="carry-out"/>
                                    <div slot="title" style="width: 100%" class="flex">
                                        <span>{{ item.Name }}</span>
                                        <span v-show="treeSelected == index">
                      <a-icon
                              type="plus-circle"
                              style="font-size: 16px;margin-right:3px;color:rgba(0, 0, 0, 0.65)"
                              @click.stop="_addTree([index])"
                      />
                      <i
                              class="iconfont icon-bianji icon-btn"
                              style="font-size: 16px;margin-right:3px"
                              @click.stop="_setTree([index], item.Name, item)"
                      ></i>
                      <a-popconfirm
                              placement="bottom"
                              title="您确定要删除吗?"
                              @confirm="_delTree([index])"
                              okText="确定"
                              cancelText="取消"
                      >
                        <i
                                class="iconfont icon-shanchu- icon-btn"
                                @click.stop
                        ></i>
                      </a-popconfirm>
                                            <!-- <i class="iconfont icon-shanchu- icon-btn" @click.stop="_delTree([index])"></i> -->
                    </span>
                                    </div>

                                    <template
                                            v-if="item.Children"
                                            v-for="(tab, tabIndex) in item.Children"
                                    >
                                        <a-icon slot="icon" type="carry-out"/>
                                        <a-tree-node :key="tab.Key">
                                            <a-icon slot="icon" type="carry-out"/>
                                            <div slot="title" style="width: 100%" class="flex">
                                                <span>{{ tab.Name }}</span>
                                                <span v-show="treeSelected == index + '-' + tabIndex">
                          <a-icon
                                  type="plus-circle"
                                  style="font-size: 16px;margin-right:3px;color:rgba(0, 0, 0, 0.65)"
                                  @click.stop="_addTree([index, tabIndex])"
                          />
                          <i
                                  class="iconfont icon-bianji icon-btn"
                                  style="font-size: 16px;margin-right:3px"
                                  @click.stop="
                              _setTree([index, tabIndex], tab.Name, tab)
                            "
                          ></i>
                                                    <!-- <i class="iconfont icon-shanchu- icon-btn" @click.stop="_delTree([index,tabIndex])"></i> -->
                          <a-popconfirm
                                  placement="bottom"
                                  title="您确定要删除吗?"
                                  @confirm="_delTree([index, tabIndex])"
                                  okText="确定"
                                  cancelText="取消"
                          >
                            <i
                                    class="iconfont icon-shanchu- icon-btn"
                                    @click.stop
                            ></i>
                          </a-popconfirm>
                        </span>
                                            </div>

                                            <template
                                                    v-if="tab.Children"
                                                    v-for="(tab2, tab2Index) in tab.Children"
                                            >
                                                <a-icon slot="icon" type="carry-out"/>
                                                <a-tree-node :key="tab2.Key">
                                                    <a-icon slot="icon" type="carry-out"/>
                                                    <div slot="title" style="width: 100%" class="flex">
                                                        <span>{{ tab2.Name }}</span>
                                                        <span
                                                                v-show="
                                treeSelected ==
                                  index + '-' + tabIndex + '-' + tab2Index
                              "
                                                        >
                              <a-icon
                                      type="plus-circle"
                                      style="font-size: 16px;margin-right:3px;color:rgba(0, 0, 0, 0.65)"
                                      @click.stop="
                                  _addTree([index, tabIndex, tab2Index])
                                "
                              />
                              <i
                                      class="iconfont icon-bianji icon-btn"
                                      style="font-size: 16px;margin-right:3px"
                                      @click.stop="
                                  _setTree(
                                    [index, tabIndex, tab2Index],
                                    tab2.Name,
                                    tab2
                                  )
                                "
                              ></i>
                                                            <!-- <i class="iconfont icon-shanchu- icon-btn" @click.stop="_delTree([index,tabIndex,tab2Index])"></i> -->

                              <a-popconfirm
                                      placement="bottom"
                                      title="您确定要删除吗?"
                                      @confirm="
                                  _delTree([index, tabIndex, tab2Index])
                                "
                                      okText="确定"
                                      cancelText="取消"
                              >
                                <i
                                        class="iconfont icon-shanchu- icon-btn"
                                        @click.stop
                                ></i>
                              </a-popconfirm>
                            </span>
                                                    </div>

                                                    <template
                                                            v-if="tab2.Children"
                                                            v-for="(tab3, tab3Index) in tab2.Children"
                                                    >
                                                        <a-icon slot="icon" type="carry-out"/>
                                                        <a-tree-node :key="tab3.Key">
                                                            <a-icon slot="icon" type="carry-out"/>
                                                            <div
                                                                    slot="title"
                                                                    style="width: 100%"
                                                                    class="flex"
                                                            >
                                                                <span>{{ tab3.Name }}</span>
                                                                <span
                                                                        v-show="
                                    treeSelected ==
                                      index +
                                        '-' +
                                        tabIndex +
                                        '-' +
                                        tab2Index +
                                        '-' +
                                        tab3Index
                                  "
                                                                >
                                  <!--<a-icon type="plus-circle" style="font-size: 16px;margin-right:3px;color:rgba(0, 0, 0, 0.65)" @click.stop="_addTree([index,tabIndex,tab2Index,tab3Index])"/>-->
                                  <i
                                          class="iconfont icon-bianji icon-btn"
                                          style="font-size: 16px;margin-right:3px"
                                          @click.stop="
                                      _setTree(
                                        [index, tabIndex, tab2Index, tab3Index],
                                        tab3.Name,
                                        tab3
                                      )
                                    "
                                  ></i>
                                                                    <!-- <i class="iconfont icon-shanchu- icon-btn" @click.stop="_delTree([index,tabIndex,tab2Index,tab3Index])"></i> -->

                                  <a-popconfirm
                                          placement="bottom"
                                          title="您确定要删除吗?"
                                          @confirm="
                                      _delTree([
                                        index,
                                        tabIndex,
                                        tab2Index,
                                        tab3Index
                                      ])
                                    "
                                          okText="确定"
                                          cancelText="取消"
                                  >
                                    <i
                                            class="iconfont icon-shanchu- icon-btn"
                                            @click.stop
                                    ></i>
                                  </a-popconfirm>
                                </span>
                                                            </div>
                                                        </a-tree-node>
                                                    </template>
                                                </a-tree-node>
                                            </template>
                                        </a-tree-node>
                                    </template>
                                </a-tree-node>
                            </template>
                        </a-tree>
                    </div>
                    <!--&lt;!&ndash;题目信息&ndash;&gt;-->
                    <div
                            class="tree"
                            style="flex: 1;margin-right:0"
                            v-if="treeSelected != -1"
                    >
                        <div class="flex">
                            <div class="tree-title">题目信息</div>
                            <div v-if="workBookDetailTableData.length != 0">
                                <!-- :disabled="workBookDetailTableData[0].TypeId?false:true" -->
                                <a-button
                                        type="primary"
                                        style="margin-right: 10px;"
                                        @click="_showWorkQuDrawerVisible"
                                >添加题型
                                </a-button
                                >
                                <a-button type="primary" @click="_showWorkBookVisible"
                                >添加题目
                                </a-button
                                >
                                <!-- :disabled="workBookDetailTableData[0].TypeId?true:false"  -->
                            </div>
                            <div v-else>
                                <a-button
                                        type="primary"
                                        style="margin-right: 10px;"
                                        @click="_showWorkQuDrawerVisible"
                                >添加题型
                                </a-button
                                >
                                <a-button type="primary" @click="_showWorkBookVisible"
                                >添加题目
                                </a-button
                                >
                            </div>
                        </div>

                        <div style="margin-top: 20px;user-select: none;cursor: all-scroll">
                            <a-table
                                    bordered
                                    :columns="workBookDetailTableColumns"
                                    :dataSource="workBookTableColumnsData"
                                    :pagination="false"
                            >
                                <!-- :customRow="click" -->
                                <div slot="setParameter" slot-scope="text, record, index">
                                    <a-input
                                            style="width: 50px;text-align: center"
                                            v-model="workBookTableColumnsData[index].ConfigSet"
                                            @pressEnter="
                      _onConfigSet(
                        $event,
                        record,
                        0,
                        workBookTableColumnsData[index].ConfigSet
                      )
                    "
                                    ></a-input>
                                    <!--<span>{{text}}</span> <i class="iconfont icon-bianji icon-btn" style="font-size: 18px;margin-left: 5px"></i>-->
                                </div>
                                <template slot="Nsort" slot-scope="text, record, index">
                                    <a-input-number id="inputNumber" v-model="record.Nsort" :min="0" @change="onChange(record)" />
                                </template>
                                <div
                                        slot="qrcodeUrl"
                                        slot-scope="text, record, index"
                                        @click.stop="_showQrCode(record)"
                                >
                                    <a-popover placement="bottom" trigger="click">
                                        <template slot="content">
                                            <div
                                                    :id="'view-qrcode' + record.Id"
                                                    style="width: 100px;height: 100px;margin:0 auto"
                                            ></div>
                                        </template>
                                        <template slot="title">
                                            <span>扫码预览</span>
                                            <span class="copy_qrcode_url" @click="_copy_qrcode_url(record,$event)">复制链接(pc)</span>
                                            <span class="copy_qrcode_url" @click="_copy_qrcode_url(record,$event,1)">复制链接(app单条)</span>
                                            <span class="copy_qrcode_url" @click="_copy_qrcode_url(record,$event,2)">复制链接(app多条)</span>
                                        </template>
                                        <img style="width: 40px;height: 40px;" src="https://homework.sikegroup.com/home-work-qrcode.png" alt=""/>
                                    </a-popover>
                                </div>
                                <div slot="operation" slot-scope="text, record, index">
                                    <i
                                            class="iconfont icon-bianji icon-btn"
                                            style="font-size: 18px;margin-right: 5px;"
                                            @click.stop="_questionShow(index)"
                                    ></i>
                                    <a-popconfirm
                                            title="您确定要删除吗?"
                                            @confirm="_delworkBookDetailTabl(index, 0)"
                                            okText="确定"
                                            cancelText="取消"
                                    >
                                        <i class="iconfont icon-shanchu- icon-btn"></i>
                                    </a-popconfirm>
                                </div>
                            </a-table>
                            <!--<div class="pagination" v-if="tableDataCount>10">-->
                            <!--<a-pagination :total="tableDataCount" style="display: inline-block;margin-top:20px;" @change="_tablePage" />-->
                            <!--</div>-->
                        </div>

                        <div style="margin-top: 20px;user-select: none;cursor: all-scroll">
                            <a-table
                                    bordered
                                    :columns="workBookDetailTableTitleColumns"
                                    :dataSource="workBookDetailTableColumnsData"
                                    :pagination="false"
                            >
                                <!-- :customRow="click" -->
                                <div slot="setParameter" slot-scope="text, record, index">
                                    <a-input
                                            style="width: 50px;text-align: center"
                                            v-model="workBookDetailTableColumnsData[index].ConfigSet"
                                            @pressEnter="
                      _onConfigSet(
                        $event,
                        record,
                        1,
                        workBookDetailTableColumnsData[index].ConfigSet
                      )
                    "
                                    ></a-input>
                                    <!--<span>{{text}}</span> <i class="iconfont icon-bianji icon-btn" style="font-size: 18px;margin-left: 5px"></i>-->
                                </div>

                                <div
                                        slot="qrcodeUrl"
                                        slot-scope="text, record, index"
                                        @click.stop="_showQrCode(record)"
                                >
                                    <a-popover placement="bottom" trigger="click">
                                        <template slot="content">
                                            <div
                                                    :id="'view-qrcode' + record.Id"
                                                    style="width: 100px;height: 100px;margin:0 auto"
                                            ></div>
                                        </template>
                                        <template slot="title">
                                            <span>扫码预览</span>
                                            <span class="copy_qrcode_url" @click="_copy_qrcode_url(record,$event)">复制链接</span>

                                        </template>
                                        <img
                                                style="width: 40px;height: 40px;"
                                                src="https://homework.sikegroup.com/home-work-qrcode.png"
                                                alt=""
                                        />
                                    </a-popover>
                                </div>
                                <div slot="operation" slot-scope="text, record, index">
                                    <a-popconfirm
                                            title="您确定要删除吗?"
                                            @confirm="_delworkBookDetailTabl(index, 1)"
                                            okText="确定"
                                            cancelText="取消"
                                    >
                                        <i class="iconfont icon-shanchu- icon-btn"></i>
                                    </a-popconfirm>
                                </div>
                            </a-table>
                            <!--<div class="pagination" v-if="tableDataCount>10">-->
                            <!--<a-pagination :total="tableDataCount" style="display: inline-block;margin-top:20px;" @change="_tablePage" />-->
                            <!--</div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <a-drawer
                title="题目设置"
                placement="right"
                width="1180"
                :closable="false"
                @close="_questionClose"
                :visible="questionVisible"
        >
            <QuestionSetPage
                    :QuestionId="QuestionId"
                    @fathermethod="_materialVisible"
            ></QuestionSetPage>
        </a-drawer>
        <!--练习题创建新节点-->
        <a-drawer
                title="节点设置"
                placement="right"
                width="400"
                :closable="false"
                @close="_newTreeNodeClose"
                :visible="TreeInput"
        >
            <div style="padding-bottom: 55px;height: 100%">
                <div class="container" style="margin-top: 0;justify-content: end">
          <span
                  style="display: inline-block;width: 70px;vertical-align: middle;line-height: 32px"
          >节点名称</span
          >
                    <a-input
                            style="margin-right: 15px;width:220px;vertical-align: middle"
                            v-model="addTreeInput"
                    ></a-input>
                </div>

                <div
                        class="container"
                        style="justify-content: end"
                        v-show="addTreeInput"
                >
          <span
                  style="display: inline-block;width: 70px;vertical-align: middle;line-height: 32px"
          >预览</span
          >
                    <div id="alertQrCode" style="width: 100px;height: 100px;"></div>
                </div>
                <div class="alert-footer">
                    <a-button style="margin-right:25px" @click="_newTreeNodeClose"
                    >取消
                    </a-button
                    >
                    <a-button type="primary" @click.stop="_confirmAddTree">确定</a-button>
                </div>
            </div>
        </a-drawer>
        <!--添加题型-->
        <a-drawer
                title="添加题型"
                placement="right"
                width="400"
                :closable="false"
                @close="_addQuDrawerClose"
                :visible="addQuDrawer"
        >
            <div style="padding-bottom: 55px;height: 100%">
                <a-tree
                        :treeData="treeData2"
                        defaultExpandAll
                        checkable
                        :checkedKeys="addQuDrawerCheck"
                        @check="_addQuDrawerCheck"
                />
                <div class="alert-footer">
                    <a-button style="margin-right:25px" @click="_addQuDrawerClose"
                    >取消
                    </a-button
                    >
                    <a-button type="primary" @click="_configAddQuDrawer">确定</a-button>
                </div>
            </div>
        </a-drawer>
        <!--练习册添加题目-->
        <a-drawer
                title="添加题目"
                placement="right"
                width="1180"
                :closable="false"
                @close="_workBookClose"
                :visible="workBookVisible"
        >
            <div style="padding-bottom: 55px;display: flex;height: 100%">
                <div class="container" style="margin-top: 0">
                    <div
                            class="content"
                            style="border: 0;border: none;padding: 0;margin: 0"
                    >
                        <div class="template-list" style="padding: 0">
                            <div class="search-div">
                                <div class="condition">
                                    <span>学习进度</span>
                                    <a-select
                                            style="width:89px;margin-right: 15px;"
                                            v-model="searcheSchedule_1"
                                    >
                                        <a-select-option
                                                :value="index"
                                                v-for="(item, index) in 10"
                                        >{{ index }}
                                        </a-select-option
                                        >
                                    </a-select>

                                    <a-select
                                            style="width:89px;margin-right: 15px;"
                                            v-model="searcheSchedule_2"
                                    >
                                        <a-select-option
                                                :value="index"
                                                v-for="(item, index) in 12"
                                        >{{ index }}
                                        </a-select-option
                                        >
                                    </a-select>

                                    <a-select style="width:90px;" v-model="searcheSchedule_3">
                                        <a-select-option :value="item" v-for="item in 4">{{
                                            item
                                            }}
                                        </a-select-option>
                                    </a-select>
                                </div>

                                <!-- <div class="condition">
                                                    <span>状态</span>
                                                    <a-select style="width: 90px" v-model="searchStatus">
                                                        <a-select-option value="">全部</a-select-option>
                                                        <a-select-option value="1">启用</a-select-option>
                                                        <a-select-option value="0">禁用</a-select-option>
                                                    </a-select>
                                                </div> -->
                                <div class="condition" style="white-space: nowrap">
                                    <span>特性</span>
                                    <a-input
                                            v-model="features"
                                            style="width: 200px;"
                                            placeholder="搜索特性"
                                    ></a-input>
                                </div>
                                <div class="condition" style="white-space: nowrap">
                                    <a-input
                                            v-model="qTitle"
                                            style="width: 200px;margin-right: 15px;"
                                            placeholder="搜索题目"
                                    ></a-input>
                                </div>
                                <a-button style="margin-right: 20px;" @click="_reset()"
                                >重置
                                </a-button
                                >
                                <a-button type="primary" @click="_searchData">查询</a-button>
                            </div>
                            <div
                                    class="flex"
                                    style="margin-top: 10px;margin-right: 20px;align-items: flex-start;"
                            >
                                <div class="tree" style="margin-right: 10px;height: 100%">
                                    <!--<div class="tree-title">题库分类</div>-->
                                    <!--<div class="tree-title" style="cursor: pointer;padding-left: 10px" @click="_allData()">全部</div>-->
                                    <a-tree
                                            :treeData="treeData2"
                                            defaultExpandAll
                                            @select="_treeSelect2"
                                    />
                                </div>
                                <div style="flex:1">
                                    <a-table
                                            bordered
                                            :columns="AlertWorkBookTableColumns"
                                            :dataSource="tableData"
                                            :showAlertInfo="true"
                                            :pagination="false"
                                            :rowSelection="{
                      selectedRowKeys: bookSelectedRowKeys,
                      onChange: bookOnChange
                    }"
                                    >
                                        <div slot="Title" slot-scope="text, record, index">
                                            <div
                                                    style="width: 350px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis"
                                            >
                                                {{ text }}
                                            </div>
                                        </div>
                                        <div slot="status" slot-scope="text, record, index">
                      <span
                              class="status-icon"
                              :style="
                          text == '禁用'
                            ? 'background: #E81B1C'
                            : 'background: #4199F3'
                        "
                      ></span>
                                            {{ text }}
                                        </div>
                                    </a-table>
                                    <div class="pagination" v-if="tableDataCount > 10">
                                        <a-pagination
                                                :total="tableDataCount"
                                                style="display: inline-block;margin-top:20px;"
                                                @change="_tablePage"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert-footer">
                    <a-button style="margin-right:25px" @click="_workBookClose"
                    >取消
                    </a-button
                    >
                    <a-button type="primary" @click="_addQuestionData">确定</a-button>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
    import QRCode from 'qrcodejs2'
    import QuestionSetPage from 'pages/HomeWork/QuestionSetPage'
    export default {
        name: 'HomeWork',
        components: {QuestionSetPage},
        data() {
            return {
                features: '',
                QuestionId: '',
                QuestionIdIndex: null,
                workBookTableCount: 0,
                workBookStatus: '', //查询状态
                workBookName: '', //查询名称
                questionVisible: false,
                workBookTableColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        width: 70,
                        align: 'center'
                    },
                    {
                        title: '练习册',
                        dataIndex: 'Name',
                        align: 'center'
                    },
                    {
                        title: '状态',
                        dataIndex: 'State',
                        width: 100,
                        align: 'center',
                        scopedSlots: {customRender: 'status'}
                    },
                    {
                        title: '课程图标',
                        dataIndex: 'IconUrl',
                        align: 'center',
                        width: 100,
                        scopedSlots: {customRender: 'icon'}
                    },
                    {
                        title: '年龄段',
                        dataIndex: 'Progress',
                        align: 'center'
                    },
                    {
                        title: '练习大纲',
                        dataIndex: 'CategoryCount',
                        align: 'center'
                    },
                    {
                        title: '创建人',
                        dataIndex: 'CreateUserName',
                        align: 'center'
                    },
                    {
                        title: '上传时间',
                        dataIndex: 'CreateTime',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 100,
                        align: 'center',
                        scopedSlots: {customRender: 'operation'}
                    }
                ], //练习册头部
                workBookTableData: [], //练习册数据
                workBookDetailTableColumns: [
                    {
                        title: '题号',
                        dataIndex: 'sNumber',
                        width: 70,
                        align: 'center'
                    },
                    {
                        title: '题目',
                        dataIndex: 'Title'
                    },
                    {
                        title: '分类',
                        dataIndex: 'CategoryName',
                        align: 'center'
                    },
                    {
                        title: '顺序',
                        dataIndex: 'Nsort',
                        align: 'center',
                        scopedSlots: {customRender: 'Nsort'}
                    },
                    {
                        title: '效果预览',
                        dataIndex: 'QrcodeUrl',
                        align: 'center',
                        scopedSlots: {customRender: 'qrcodeUrl'}
                    },
                    {
                        title: '参数设置',
                        dataIndex: 'setParameter',
                        align: 'center',
                        scopedSlots: {customRender: 'setParameter'}
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 100,
                        align: 'center',
                        scopedSlots: {customRender: 'operation'}
                    }
                ], //练习册详情头部
                workBookDetailTableTitleColumns: [
                    {
                        title: '题号',
                        dataIndex: 'sNumber',
                        width: 70,
                        align: 'center'
                    },
                    {
                        title: '题目类型',
                        dataIndex: 'Title',
                        align: 'center'
                    },
                    {
                        title: '效果预览',
                        dataIndex: 'QrcodeUrl',
                        align: 'center',
                        scopedSlots: {customRender: 'qrcodeUrl'}
                    },
                    {
                        title: '题目数量',
                        dataIndex: 'QuestionCount',
                        align: 'center'
                    },
                    {
                        title: '参数设置',
                        dataIndex: 'setParameter',
                        align: 'center',
                        scopedSlots: {customRender: 'setParameter'}
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 100,
                        align: 'center',
                        scopedSlots: {customRender: 'operation'}
                    }
                ],
                workBookDetailTableData: [], //练习册详情数据
                setWorkBook: -1,
                bookName: '', //名称
                bookIconUrl: '', //图片地址
                bookCourseDisplay: 1, //课程是否显示
                bookIsRec: 1, //是否推荐
                bookIntro: '', //课程介绍,
                bookCategoryData: [
                    {
                        Name: '练习大纲',
                        Key: 'maxNode',
                        Children: []
                    }
                ], //树节点
                ageBracket: [], //年龄段
                ageList: [], //年龄段数据
                isNew: false,
                treeSelected: -1, //选择的节点下标
                TreeInput: false, //是否显示修改框
                addTree: -1, //要添加的节点下标
                setTree: -1, //要修改的节点名称
                addTreeInput: '', //tree添加的文字
                workBookVisible: false, //添加题目弹出框
                bookSelectedRowKeys: [],
                bookSelectedRow: [],
                downItem: '', //鼠标按下获取的对象
                downIndex: '', //鼠标按下获取的下标

                searchMaterialName: '', //素材搜索，
                homeBookClassify: [],
                homeBookClassifySelect: '',
                questionState: 1, //题型状态

                addQuDrawer: false,
                addQuDrawerCheck: [],
                treeData2: [],
                AlertWorkBookTableColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        width: 70,
                        align: 'center'
                    },
                    {
                        title: '题目',
                        dataIndex: 'Title',
                        scopedSlots: {customRender: 'Title'}
                    },
                    {
                        title: '特性',
                        dataIndex: 'features'
                    },
                    {
                        title: '状态',
                        dataIndex: 'State',
                        width: 100,
                        align: 'center',
                        scopedSlots: {customRender: 'status'}
                    },
                    {
                        title: '分类',
                        dataIndex: 'CategoryName',
                        width: 130,
                        align: 'center'
                    },
                    {
                        title: '学习进度',
                        dataIndex: 'Progress',
                        width: 150,
                        align: 'center'
                    }
                ],
                AlertWorkBookTableTable: [],
                searcheSchedule_3: '',
                searcheSchedule_2: '',
                searcheSchedule_1: '',
                searchStatus: '',
                searchClass: '',
                tablePage: 1,
                tableData: [],
                tableDataCount: 10,
                selectData: '',
                qTitle: '',
                workBookTableColumnsData: [], //题目数据
                workBookDetailTableColumnsData: [], //题型数据
                topid: '',
                addQuestionData: [],
                selectCategoryId: '',
                tmpWorkBookId: '',
                isNewAdd: 0,
                showStyleCheck:0,
            }
        },
        created() {
            let self = this

            //获取题库分类
            this.$axios.get(1206, {label: 'HomeWorkQuestion'}, res => {
                if (res.data.code == 1) {
                    let data = [
                        {
                            title: '全部分类',
                            children: res.data.data,
                            key: '',
                            value: '',
                            Id: ''
                        }
                    ]
                    this.treeData2 = data
                }
            })

            //获取练习册分类
            this.$axios.get(1206, {label: 'WorkBook'}, res => {
                if (res.data.code == 1) {
                    let data = [
                        {
                            title: '全部分类',
                            children: res.data.data,
                            key: '',
                            value: '',
                            Id: ''
                        }
                    ]
                    this.homeBookClassify = data
                }
            })

            //获取题库列表
            this.$axios.get(
                5836126,
                {
                    CategoryId: '',
                    State: '',
                    Progress: '',
                    Type: '',
                    Page: this.tablePage
                },
                res => {
                    if (res.data.code == 1) {
                        this.tableData = res.data.data
                        this.tableDataCount = res.data.count
                    }
                }
            )

            for (let i = 0; i < 13; i++) {
                self.ageList.push({value: i, label: i, children: []})
                for (let j = i + 1; j < 13; j++) {
                    self.ageList[i].children.push({value: j, label: j})
                }
            }
            this._workBookInfo()
        },
        methods: {
            onChange(item){
                console.log(item)
                this.$axios.post(5836197, {Id:item.Id,Sort:item.Nsort}, res => {
                    if (res.data.code == 1) {
                        this.$message.success('修改成功')
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
            },
            _showNewPage() {
                this.isNew = true
                this.isNewAdd = 1
            },
            _parseData(data) {
                let length = data.length
                let j = 1
                let n = 1
                for (var i = 0; i < length; i++) {
                    if (data[i].TypeId) {
                        data[i].sNumber = j
                        this.workBookDetailTableColumnsData.push(data[i])
                        j++
                    } else {
                        data[i].sNumber = n
                        this.workBookTableColumnsData.push(data[i])
                        n++
                    }
                }
            },
            _showQrCode(data) {
                let self = this
                console.log(data);
                setTimeout(function () {
                    let dom = document.getElementById('view-qrcode' + data.Id)
                    dom.innerHTML = ''
                    if (self.showStyleCheck == 0) {
                        if (!data.TypeId) {
                            self._addQrCode(
                                'https://q.qitianzhen.cn/homework/workBook?id=' + data.QuestionId,
                                dom,
                                100
                            )
                        } else {
                            self._addQrCode(
                                'https://q.qitianzhen.cn/homework/workBook?workBook=' +
                                self.selectData.Id +
                                '&questionTypeId=' +
                                data.TypeId,
                                dom,
                                100
                            )
                        }
                    } else {
                        self._addQrCode(data.QrcodeUrl_Pad,dom,100)
                    }
                })
            },
            _tablePage(page) {
                this.tablePage = page
                this._searchData()
                this.bookSelectedRowKeys = []
            },
            _searchData() {
                let jindu = this.searcheSchedule_1
                    ? this.searcheSchedule_1 +
                    '-' +
                    this.searcheSchedule_2 +
                    '-' +
                    this.searcheSchedule_3
                    : ''
                this.$axios.get(
                    5836126,
                    {
                        CategoryId: this.searchClass,
                        State: 1, //this.searchStatus,
                        Progress: jindu,
                        Type: this.searchType,
                        Page: this.tablePage,
                        Year: this.searcheSchedule_1,
                        Month: this.searcheSchedule_2,
                        Week: this.searcheSchedule_3,
                        Title: this.qTitle,
                        features: this.features
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.tableData = res.data.data
                            this.tableDataCount = res.data.count
                        } else {
                            this.tableData = []
                            this.tableDataCount = 0
                        }
                    }
                )
            },
            bookOnChange(selectedRowKeys, selectedRows) {
                //选中的数据的key
                this.bookSelectedRowKeys = selectedRowKeys
                this.bookSelectedRow = selectedRows
                console.log(selectedRowKeys)
                console.log(selectedRows)
            },
            _treeSelect2(key) {
                this.tablePage = 1
                this.searchClass = key[0]
                this._searchData()
            },
            _addQuestionData() {
                this.workBookVisible = false
                console.log(this.bookSelectedRow)
                this.workBookDetailTableData = this.workBookDetailTableData.concat(
                    this.bookSelectedRow
                )
                let arr = this.treeSelected.split('-')
                this.workBookDetailTableData.map((res, index) => {
                    res.sNumber = index + 1
                    if (!res.QuestionId) {
                        res.QuestionId = res.Id
                    }
                })
                let category_id = ''
                if (arr.length == 1) {
                    this.bookCategoryData[0].Children[
                        arr[0]
                        ].QuestionData = this.workBookDetailTableData
                    this.bookCategoryData[0].Children[arr[0]].qtype = 0
                    category_id = this.bookCategoryData[0].Children[arr[0]].Id
                } else if (arr.length == 2) {
                    this.bookCategoryData[0].Children[arr[0]].Children[
                        arr[1]
                        ].QuestionData = this.workBookDetailTableData
                    this.bookCategoryData[0].Children[arr[0]].Children[arr[1]].qtype = 0
                    category_id = this.bookCategoryData[0].Children[arr[0]].Children[arr[1]]
                        .Id
                } else if (arr.length == 3) {
                    this.bookCategoryData[0].Children[arr[0]].Children[arr[1]].Children[
                        arr[2]
                        ].QuestionData = this.workBookDetailTableData
                    this.bookCategoryData[0].Children[arr[0]].Children[arr[1]].Children[
                        arr[2]
                        ].qtype = 0
                    category_id = this.bookCategoryData[0].Children[arr[0]].Children[arr[1]]
                        .Children[arr[2]].Id
                } else if (arr.length == 4) {
                    this.bookCategoryData[0].Children[arr[0]].Children[arr[1]].Children[
                        arr[2]
                        ].Children[arr[3]].QuestionData = this.workBookDetailTableData
                    this.bookCategoryData[0].Children[arr[0]].Children[arr[1]].Children[
                        arr[2]
                        ].Children[arr[3]].qtype = 0
                    category_id = this.bookCategoryData[0].Children[arr[0]].Children[arr[1]]
                        .Children[arr[2]].Children[arr[3]].Id
                }

                let WorkBookId = this._getWorkBookId()
                this._initAddQustionData(WorkBookId, category_id, 0, this.bookSelectedRow)

                this.bookSelectedRow = []
                this.bookSelectedRowKeys = []
                this.workBookDetailTableColumnsData = []
                this.workBookTableColumnsData = []
                this._parseData(this.workBookDetailTableData)
            },
            _workBookClose() {
                this.workBookVisible = false
                this.bookSelectedRowKeys = []
            },
            _addQuDrawerCheck(value) {
                this.addQuDrawerCheck = value
            },
            _addQuDrawerClose() {
                this.addQuDrawer = false
                this.addQuDrawerCheck = []
            },
            _configAddQuDrawer() {
                this.$axios.get(
                    5836168,
                    {category_id: this.addQuDrawerCheck.join(',')},
                    res => {
                        if (res.data.code == 1) {
                            let category_id = ''
                            let WorkBookId = this._getWorkBookId()
                            let arr = this.treeSelected.split('-')
                            this.workBookDetailTableData = []
                            this.workBookDetailTableData = res.data.data.concat(
                                this.workBookTableColumnsData
                            )
                            this.workBookDetailTableData.map((res, index) => {
                                res.sNumber = index + 1
                                if (!res.QuestionId) {
                                    res.TypeId = res.Id
                                    res.QuestionId = res.Id
                                }
                            })
                            if (arr.length == 1) {
                                this.bookCategoryData[0].Children[
                                    arr[0]
                                    ].QuestionData = this.workBookDetailTableData
                                this.bookCategoryData[0].Children[arr[0]].qtype = 1
                                category_id = this.bookCategoryData[0].Children[arr[0]].Id
                            } else if (arr.length == 2) {
                                this.bookCategoryData[0].Children[arr[0]].Children[
                                    arr[1]
                                    ].QuestionData = this.workBookDetailTableData
                                this.bookCategoryData[0].Children[arr[0]].Children[
                                    arr[1]
                                    ].qtype = 1
                                category_id = this.bookCategoryData[0].Children[arr[0]].Children[
                                    arr[1]
                                    ].Id
                            } else if (arr.length == 3) {
                                this.bookCategoryData[0].Children[arr[0]].Children[
                                    arr[1]
                                    ].Children[arr[2]].QuestionData = this.workBookDetailTableData
                                this.bookCategoryData[0].Children[arr[0]].Children[
                                    arr[1]
                                    ].Children[arr[2]].qtype = 1
                                category_id = this.bookCategoryData[0].Children[arr[0]].Children[
                                    arr[1]
                                    ].Children[arr[2]].Id
                            } else if (arr.length == 4) {
                                this.bookCategoryData[0].Children[arr[0]].Children[
                                    arr[1]
                                    ].Children[arr[2]].Children[
                                    arr[3]
                                    ].QuestionData = this.workBookDetailTableData
                                this.bookCategoryData[0].Children[arr[0]].Children[
                                    arr[1]
                                    ].Children[arr[2]].Children[arr[3]].qtype = 1
                                category_id = this.bookCategoryData[0].Children[arr[0]].Children[
                                    arr[1]
                                    ].Children[arr[2]].Children[arr[3]].Id
                            }
                            this.addQuDrawer = false
                            this.addQuDrawerCheck = []
                            this.workBookDetailTableColumnsData = []
                            this.workBookTableColumnsData = []
                            this._parseData(this.workBookDetailTableData)
                            this._initAddQustionData(WorkBookId, category_id, 1, res.data.data)
                        } else {
                        }
                    }
                )
            },
            _initAddQustionData(WorkBookId, category_id, qtype, data) {
                if (data.length == 0) {
                    return
                }
                let addQuestionData = {}
                for (var i = 0; i < data.length; i++) {
                    addQuestionData[i] = {}
                    addQuestionData[i]['qtype'] = qtype
                    addQuestionData[i]['TypeId'] = ''
                    addQuestionData[i]['QuestionId'] = ''
                    addQuestionData[i]['QuestionCount'] = 0
                    if (qtype == 1) {
                        addQuestionData[i]['TypeId'] = data[i].QuestionId
                        addQuestionData[i]['QuestionCount'] = data[i].QuestionCount
                    } else {
                        addQuestionData[i]['QuestionId'] = data[i].QuestionId
                    }
                }
                if (addQuestionData.length == 0) {
                    return
                }

                let subData = {
                    WorkBookId: WorkBookId,
                    CategoryId: category_id,
                    CreateUserId: userInfo.uid,
                    CreateUserName: userInfo.name,
                    QuestionData: JSON.stringify(addQuestionData)
                }
                this.$axios.post(7000005, subData, res => {
                    if (res.data.code == 1) {
                        console.log(res.data.message)
                    } else {
                        console.log(res.data.message)
                    }
                })
            },
            _showWorkBookVisible() {
                let self = this
                this.workBookVisible = true
                // this.tableData.map((res,index)=>{
                //     if(self.workBookDetailTableData){
                //         self.workBookDetailTableData.map((item)=>{
                //             if(item.QuestionId == res.Id){
                //                 self.bookSelectedRowKeys.push(index)
                //             }
                //         })
                //     }
                // });
            },
            _showWorkQuDrawerVisible() {
                this.addQuDrawer = true
                this.addQuDrawerCheck = []
                this.workBookDetailTableColumnsData.map(res => {
                    this.addQuDrawerCheck.push(res.TypeId)
                })
            },
            _confirmAddTree() {
                if (this.addTreeInput == '') {
                    return
                }
                let nodeKey = ''
                let Pid = ''
                let category_id = ''
                let type = ''
                if (this.setTree === -1) {
                    let item = {
                        Name: this.addTreeInput,
                        Key: '',
                        QuestionData: [],
                        Children: []
                    }
                    type = 'add'
                    if (this.addTree == '') {
                        Pid = this.bookCategoryData[0].Id ? this.bookCategoryData[0].Id : ''
                        if (this.isNewAdd == 1) {
                            Pid = this.topid
                        }
                        item.Key = String(this.bookCategoryData[0].Children.length)
                        this.bookCategoryData[0].Children.push(item)
                    } else if (this.addTree.length == 1) {
                        item.Key =
                            this.addTree[0] +
                            '-' +
                            this.bookCategoryData[0].Children[this.addTree[0]].Children.length
                        this.bookCategoryData[0].Children[this.addTree[0]].Children.push(item)
                        Pid = this.bookCategoryData[0].Children[this.addTree[0]].Id
                    } else if (this.addTree.length == 2) {
                        item.Key =
                            this.addTree[0] +
                            '-' +
                            this.addTree[1] +
                            '-' +
                            this.bookCategoryData[0].Children[this.addTree[0]].Children[
                                this.addTree[1]
                                ].Children.length
                        this.bookCategoryData[0].Children[this.addTree[0]].Children[
                            this.addTree[1]
                            ].Children.push(item)
                        Pid = this.bookCategoryData[0].Children[this.addTree[0]].Children[
                            this.addTree[1]
                            ].Id
                    } else if (this.addTree.length == 3) {
                        item.Key =
                            this.addTree[0] +
                            '-' +
                            this.addTree[1] +
                            '-' +
                            this.addTree[2] +
                            '-' +
                            this.bookCategoryData[0].Children[this.addTree[0]].Children[
                                this.addTree[1]
                                ].Children[this.addTree[2]].Children.length
                        this.bookCategoryData[0].Children[this.addTree[0]].Children[
                            this.addTree[1]
                            ].Children[this.addTree[2]].Children.push(item)
                        Pid = this.bookCategoryData[0].Children[this.addTree[0]].Children[
                            this.addTree[1]
                            ].Children[this.addTree[2]].Id
                    }
                    nodeKey = item.Key
                } else {
                    type = 'edit'
                    if (this.setTree.length == 1) {
                        this.bookCategoryData[0].Children[
                            this.setTree[0]
                            ].Name = this.addTreeInput
                        category_id = this.bookCategoryData[0].Children[this.setTree[0]].Id
                    } else if (this.setTree.length == 2) {
                        this.bookCategoryData[0].Children[this.setTree[0]].Children[
                            this.setTree[1]
                            ].Name = this.addTreeInput
                        category_id = this.bookCategoryData[0].Children[this.setTree[0]]
                            .Children[this.setTree[1]].Id
                    } else if (this.setTree.length == 3) {
                        this.bookCategoryData[0].Children[this.setTree[0]].Children[
                            this.setTree[1]
                            ].Children[this.setTree[2]].Name = this.addTreeInput
                        category_id = this.bookCategoryData[0].Children[this.setTree[0]]
                            .Children[this.setTree[1]].Children[this.setTree[2]].Id
                    } else if (this.setTree.length == 4) {
                        this.bookCategoryData[0].Children[this.setTree[0]].Children[
                            this.setTree[1]
                            ].Children[this.setTree[2]].Children[
                            this.setTree[3]
                            ].Name = this.addTreeInput
                        category_id = this.bookCategoryData[0].Children[this.setTree[0]]
                            .Children[this.setTree[1]].Children[this.setTree[2]].Children[
                            this.setTree[3]
                            ].Id
                    }
                    Pid = this.bookCategoryData[0].Children[this.setTree[0]].Pid
                    nodeKey = this.bookCategoryData[0].Children[this.setTree[0]].Key
                }
                let NodeName = this.addTreeInput
                let WorkBookId = this._getWorkBookId()
                this._categoryOptHandleForWorkBook(
                    WorkBookId,
                    Pid,
                    category_id,
                    nodeKey,
                    NodeName,
                    type
                )
                this.TreeInput = false
            },
            _newTreeNodeClose() {
                this.TreeInput = false
                this.addTree = -1
                this.setTree = -1
                this.addTreeInput = ''
            },
            _delTree(index) {
                let WorkBookId = this._getWorkBookId()
                let category_id = this.selectCategoryId
                if (index.length == 3) {
                    this.bookCategoryData[0].Children[index[0]].Children[
                        index[1]
                        ].Children.splice(index[2], 1)
                } else if (index.length == 2) {
                    this.bookCategoryData[0].Children[index[0]].Children.splice(index[1], 1)
                } else if (index.length == 4) {
                    this.bookCategoryData[0].Children[index[0]].Children[index[1]].Children[
                        index[2]
                        ].Children.splice(index[3], 1)
                } else {
                    this.bookCategoryData[0].Children.splice(index[0], 1)
                }
                this.bookCategoryData[0].Children.map((item_1, index) => {
                    item_1.Key = index
                    if (item_1.Children.length > 0) {
                        item_1.Children.map((item_2, index_2) => {
                            item_2.Key = index + '-' + index_2

                            if (item_2.Children.length > 0) {
                                item_2.Children.map((item_3, index_3) => {
                                    item_3.Key = index + '-' + index_2 + '-' + index_3

                                    if (item_3.Children.length > 0) {
                                        item_3.Children.map((item_4, index_4) => {
                                            item_4.Key =
                                                index + '-' + index_2 + '-' + index_3 + '-' + index_4
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
                this.workBookDetailTableColumnsData = []
                this.workBookTableColumnsData = []
                this._categoryOptHandleForWorkBook(
                    WorkBookId,
                    null,
                    category_id,
                    null,
                    null,
                    'del'
                )
            },
            _addTree(i) {
                setTimeout(function () {
                    document.getElementById('alertQrCode').innerHTML = ''
                })
                this.addTree = i
                this.TreeInput = true
                this.addTreeInput = ''
            },
            _setTree(i, name, data) {
                this.addTreeInput = name
                this.setTree = i
                this.TreeInput = true
                setTimeout(function () {
                    document.getElementById('alertQrCode').innerHTML = ''
                    if (data.Id) {
                        let qrcode = new QRCode('alertQrCode', {
                            width: 140,
                            height: 140,
                            text:
                            'https://q.qitianzhen.cn/homework/workBook?id=06197f3844285e5d5771968a305cbf3e&workBook=' +
                            data.Id
                        })
                    }
                })
            },
            _homeBookClassify(key) {
                this.homeBookClassifySelect = key[0]
                //不是修改时才执行
                if (this.setWorkBook == -1) {
                    this._workBookInfo()
                }
            },
            _workBookInfo(page) {
                this.workBookName = ''
                this.workBookStatus = ''
                this.$message.loading('加载中...')
                this.$axios.get(
                    5836150,
                    {
                        Name: this.workBookName,
                        CategoryId: this.homeBookClassifySelect,
                        State: this.workBookStatus,
                        Page: page
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.workBookTableData = res.data.data
                            this.workBookTableCount = res.data.count
                        } else {
                            this.workBookTableData = []
                            this.workBookTableCount = 0
                        }
                        this.$message.destroy()
                    }
                )
            },
            _delWorkBook(index) {
                this.$axios.post(
                    5836151,
                    {Id: this.workBookTableData[index].Id, UserId: userInfo.uid},
                    res => {
                        if (res.data.code == 1) {
                            this.$message.success('删除成功')
                            this.workBookTableData.splice(index, 1)
                        }
                    }
                )
            },
            _copyWorkBook(i) {
                console.log(this.workBookTableData[i])
                let data = {
                    Name: this.workBookTableData[i].Name,
                    Year: this.workBookTableData[i].Year,
                    Month: this.workBookTableData[i].Month,
                    Week: this.workBookTableData[i].Week,
                    CourseDisplay: this.workBookTableData[i].CourseDisplay,
                    IconUrl: this.workBookTableData[i].IconUrl,
                    isRec: this.workBookTableData[i].isRec,
                    Intro: this.workBookTableData[i].Intro,
                    State: this.workBookTableData[i].State,
                    CategoryId: this.workBookTableData[i].CategoryId,
                    StartAge: this.workBookTableData[i].StartAge,
                    EndAge: this.workBookTableData[i].EndAge,
                    CreateUserId: userInfo.uid,
                    CreateUserName: userInfo.name
                }
                let self = this
                this.$axios.get(
                    5836169,
                    {Id: this.workBookTableData[i].Id, UserId: userInfo.uid},
                    res => {
                        if (res.data.code == 1) {
                            data.CategoryData = JSON.stringify(res.data.data)
                            self.$axios.post(5836152, data, res => {
                                if (res.data.code == 1) {
                                    self.$message.success('复制成功')
                                    self.workBookName = ''
                                    self.workBookStatus = ''
                                    self.Page = ''
                                    self._workBookInfo()
                                } else {
                                    self.$message.error('复制失败')
                                }
                            })
                        } else {
                            self.$message.error('复制失败')
                        }
                    }
                )
            },
            _setWorkBook(i) {
                this.setWorkBook = i
                this.isNew = true
                this.$message.loading('加载中...')
                this.$axios.get(
                    5836153,
                    {Id: this.workBookTableData[i].Id, UserId: userInfo.uid},
                    res => {
                        if (res.data.code == 1) {
                            let data = res.data.data

                            this.bookName = data.WorkBookInfo.Name
                            this.ageBracket = [
                                data.WorkBookInfo.StartAge,
                                data.WorkBookInfo.EndAge
                            ]
                            this.bookIconUrl = data.WorkBookInfo.IconUrl
                            this.bookCourseDisplay = data.WorkBookInfo.CourseDisplay
                            this.bookIsRec = data.WorkBookInfo.isRec
                            this.bookIntro = data.WorkBookInfo.Intro
                            this.bookCategoryData = data.CategoryInfo
                            this.homeBookClassifySelect = data.WorkBookInfo.CategoryId
                            this.topid = this.bookCategoryData[0].Id
                            this.showStyleCheck = data.WorkBookInfo.showStyle
                        }
                        this.$message.destroy()
                    }
                )
            },
            _setBook() {
                let data = {
                    Name: this.bookName,
                    StartAge: this.ageBracket[0],
                    EndAge: this.ageBracket[1],
                    IconUrl: this.bookIconUrl,
                    UserId: userInfo.uid,
                    UserName: userInfo.name,
                    CourseDisplay: this.bookCourseDisplay,
                    isRec: this.bookIsRec,
                    Intro: this.bookIntro,
                    CategoryData: '', //JSON.stringify(this.bookCategoryData),
                    Id: this._getWorkBookId(),
                    CategoryId: this.homeBookClassifySelect,
                    showStyle:this.showStyleCheck
                }
                this.$message.loading('数据上传中...')
                this.$axios.post(5836154, data, res => {
                    if (res.data.code == 1) {
                        this.$message.success('修改成功')
                        this.workBookTableData[this.setWorkBook].Name = this.bookName
                        this.workBookTableData[this.setWorkBook].Progress =
                            this.ageBracket[0] + '-' + this.ageBracket[1]
                        this.workBookTableData[this.setWorkBook].CreateTime = '刚刚'
                        this.workBookTableData[this.setWorkBook].IconUrl = this.bookIconUrl
                        this.workBookTableData[this.setWorkBook].Intro = this.bookIntro
                        this.workBookTableData[
                            this.setWorkBook
                            ].CourseDisplay = this.bookCourseDisplay
                        this.workBookTableData[this.setWorkBook].isRec = this.bookIsRec
                        this._return()
                    } else {
                        this.$message.error('修改失败')
                    }
                    this.$message.destroy()
                })
            },
            _bookSave() {
                let data = {
                    Name: this.bookName,
                    StartAge: this.ageBracket[0],
                    EndAge: this.ageBracket[1],
                    IconUrl: this.bookIconUrl,
                    CreateUserId: userInfo.uid,
                    CreateUserName: userInfo.name,
                    CourseDisplay: this.bookCourseDisplay,
                    isRec: this.bookIsRec,
                    Intro: this.bookIntro,
                    CategoryData: '', //JSON.stringify(this.bookCategoryData),
                    CategoryId: this.homeBookClassifySelect,
                    tmpWorkBookId: this._getWorkBookId(),
                    showStyle:this.showStyleCheck
                }
                this.$message.loading('数据上传中...')
                this.$axios.post(5836152, data, res => {
                    if (res.data.code == 1) {
                        this.$message.success('添加成功')
                        let item = {
                            CourseDisplay: this.bookCourseDisplay,
                            CreateTime: '刚刚',
                            CreateUserId: userInfo.uid,
                            CreateUserName: userInfo.name,
                            IconUrl: this.bookIconUrl,
                            Id: res.data.id,
                            Intro: this.bookIntro,
                            EndAge: this.ageBracket[1],
                            Name: this.bookName,
                            Progress: this.ageBracket[0] + '-' + this.ageBracket[1],
                            State: this.bookCourseDisplay == 1 ? '启用' : '禁用',
                            StartAge: this.ageBracket[0],
                            isRec: this.bookIsRec,
                            sNumber: 0
                        }
                        this.workBookTableData.push(item)
                        this._return()
                    } else {
                        this.$message.error('添加失败')
                    }
                    this.$message.destroy()
                })
            },
            _return() {
                this.setWorkBook = -1
                this.isNew = false
                this._reactData()
            },
            _reactData() {
                this.bookName = ''
                this.ageBracket = []
                this.bookIconUrl = ''
                this.bookCourseDisplay = 1
                this.bookIsRec = 1
                this.bookIntro = ''
                this.bookCategoryData = [
                    {Name: '练习大纲', Key: 'maxNode', Children: []}
                ]
                this.workBookDetailTableData = []
                this.treeSelected = -1
            },
            _getQuestionData(selectedKeys, e) {
                let self = this
                if (selectedKeys.length == 0) {
                    return false
                }
                this.workBookDetailTableData = []
                this.workBookDetailTableColumnsData = []
                this.workBookTableColumnsData = []
                if (selectedKeys.length != 0) {
                    this.treeSelected = selectedKeys.join('-')
                } else {
                    this.treeSelected = -1
                }
                let arr = this.treeSelected.split('-')
                let id = ''
                if (arr.length == 1) {
                    this.workBookDetailTableData = this.bookCategoryData[0].Children[
                        arr[0]
                        ].QuestionData
                    id = this.bookCategoryData[0].Children[arr[0]].Id
                    this.selectData = this.bookCategoryData[0].Children[arr[0]]
                } else if (arr.length == 2) {
                    this.workBookDetailTableData = this.bookCategoryData[0].Children[
                        arr[0]
                        ].Children[arr[1]].QuestionData
                    id = this.bookCategoryData[0].Children[arr[0]].Children[arr[1]].Id
                    this.selectData = this.bookCategoryData[0].Children[arr[0]].Children[
                        arr[1]
                        ]
                } else if (arr.length == 3) {
                    this.workBookDetailTableData = this.bookCategoryData[0].Children[
                        arr[0]
                        ].Children[arr[1]].Children[arr[2]].QuestionData
                    id = this.bookCategoryData[0].Children[arr[0]].Children[arr[1]]
                        .Children[arr[2]].Id
                    this.selectData = this.bookCategoryData[0].Children[arr[0]].Children[
                        arr[1]
                        ].Children[arr[2]]
                } else if (arr.length == 4) {
                    this.workBookDetailTableData = this.bookCategoryData[0].Children[
                        arr[0]
                        ].Children[arr[1]].Children[arr[2]].Children[arr[3]].QuestionData
                    id = this.bookCategoryData[0].Children[arr[0]].Children[arr[1]]
                        .Children[arr[2]].Children[arr[3]].Id
                    this.selectData = this.bookCategoryData[0].Children[arr[0]].Children[
                        arr[1]
                        ].Children[arr[2]].Children[arr[3]]
                }
                if (!id) {
                    return false
                }
                this.selectCategoryId = id
                this.$message.loading('加载中...')
                this.$axios.get(
                    7000003,
                    {CategoryId: id, WorkBookId: this._getWorkBookId()},
                    res => {
                        if (res.data.code == 1) {
                            this.workBookDetailTableData = res.data.list

                            if (arr.length == 1) {
                                this.bookCategoryData[0].Children[
                                    arr[0]
                                    ].QuestionData = this.workBookDetailTableData
                            } else if (arr.length == 2) {
                                this.bookCategoryData[0].Children[arr[0]].Children[
                                    arr[1]
                                    ].QuestionData = this.workBookDetailTableData
                            } else if (arr.length == 3) {
                                this.bookCategoryData[0].Children[arr[0]].Children[
                                    arr[1]
                                    ].Children[arr[2]].QuestionData = this.workBookDetailTableData
                            } else if (arr.length == 4) {
                                this.bookCategoryData[0].Children[arr[0]].Children[
                                    arr[1]
                                    ].Children[arr[2]].Children[
                                    arr[3]
                                    ].QuestionData = this.workBookDetailTableData
                            }
                            this.addQuDrawerCheck = []
                            this.workBookDetailTableData.map(res => {
                                this.addQuDrawerCheck.push(res.TypeId)
                            })

                            this._parseData(this.workBookDetailTableData)
                        }
                        this.$message.destroy()
                    }
                )
            },
            _bookTreeSelect(selectedKeys, e) {
                let self = this
                if (selectedKeys.length == 0) {
                    return false
                }
                this.workBookDetailTableData = []
                this.workBookDetailTableColumnsData = []
                this.workBookTableColumnsData = []
                if (selectedKeys.length != 0) {
                    this.treeSelected = selectedKeys.join('-')
                } else {
                    this.treeSelected = -1
                }
                let arr = this.treeSelected.split('-')
                let id = ''
                if (arr.length == 1) {
                    this.workBookDetailTableData = this.bookCategoryData[0].Children[
                        arr[0]
                        ].QuestionData
                    id = this.bookCategoryData[0].Children[arr[0]].Id
                    this.selectData = this.bookCategoryData[0].Children[arr[0]]
                } else if (arr.length == 2) {
                    this.workBookDetailTableData = this.bookCategoryData[0].Children[
                        arr[0]
                        ].Children[arr[1]].QuestionData
                    id = this.bookCategoryData[0].Children[arr[0]].Children[arr[1]].Id
                    this.selectData = this.bookCategoryData[0].Children[arr[0]].Children[
                        arr[1]
                        ]
                } else if (arr.length == 3) {
                    this.workBookDetailTableData = this.bookCategoryData[0].Children[
                        arr[0]
                        ].Children[arr[1]].Children[arr[2]].QuestionData
                    id = this.bookCategoryData[0].Children[arr[0]].Children[arr[1]]
                        .Children[arr[2]].Id
                    this.selectData = this.bookCategoryData[0].Children[arr[0]].Children[
                        arr[1]
                        ].Children[arr[2]]
                } else if (arr.length == 4) {
                    this.workBookDetailTableData = this.bookCategoryData[0].Children[
                        arr[0]
                        ].Children[arr[1]].Children[arr[2]].Children[arr[3]].QuestionData
                    id = this.bookCategoryData[0].Children[arr[0]].Children[arr[1]]
                        .Children[arr[2]].Children[arr[3]].Id
                    this.selectData = this.bookCategoryData[0].Children[arr[0]].Children[
                        arr[1]
                        ].Children[arr[2]].Children[arr[3]]
                }
                //如果问题是空的话去请求数据然后添加到数组里面
                if (!this.workBookDetailTableData) {
                    this.$axios.get(
                        5836155,
                        {
                            Page: 1,
                            CategoryId: id,
                            WorkBookId: this.workBookTableData[this.setWorkBook].Id
                        },
                        res => {
                            if (res.data.code == 1) {
                                this.workBookDetailTableData = res.data.data
                                if (arr.length == 1) {
                                    this.bookCategoryData[0].Children[
                                        arr[0]
                                        ].QuestionData = this.workBookDetailTableData
                                } else if (arr.length == 2) {
                                    this.bookCategoryData[0].Children[arr[0]].Children[
                                        arr[1]
                                        ].QuestionData = this.workBookDetailTableData
                                } else if (arr.length == 3) {
                                    this.bookCategoryData[0].Children[arr[0]].Children[
                                        arr[1]
                                        ].Children[arr[2]].QuestionData = this.workBookDetailTableData
                                } else if (arr.length == 4) {
                                    this.bookCategoryData[0].Children[arr[0]].Children[
                                        arr[1]
                                        ].Children[arr[2]].Children[
                                        arr[3]
                                        ].QuestionData = this.workBookDetailTableData
                                }
                            }
                        }
                    )
                }
                this.addQuDrawerCheck = []
                this.workBookDetailTableData.map(res => {
                    this.addQuDrawerCheck.push(res.TypeId)
                })

                this._parseData(this.workBookDetailTableData)
            },
            _addQrCode(url, dom, w) {
                let self = this
                let qrcode = new QRCode(dom, {
                    text: url,
                    width: w ? w : 60,
                    height: w ? w : 60,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            },
            _delworkBookDetailTabl(i, type) {
                let tmp = []
                let id = ''
                let QuestionId = ''
                let TypeId = ''
                let category_id = ''
                if (type == 0) {
                    id = this.workBookTableColumnsData[i].Id
                    QuestionId = this.workBookTableColumnsData[i].QuestionId
                    this.workBookTableColumnsData.splice(i, 1)
                } else {
                    id = this.workBookDetailTableColumnsData[i].Id
                    TypeId = this.workBookDetailTableColumnsData[i].TypeId
                    this.workBookDetailTableColumnsData.splice(i, 1)
                }
                for (var j = 0; j < this.workBookDetailTableData.length; j++) {
                    if (this.workBookDetailTableData[j].Id == id) {
                        this.workBookDetailTableData.splice(j, 1)
                        break
                    }
                }
                let subData = {
                    WorkBookId: this._getWorkBookId(),
                    CategoryId: this.selectCategoryId,
                    QuestionId: QuestionId,
                    TypeId: TypeId,
                    UserId: userInfo.uid
                }
                this.$axios.post(7000006, subData, res => {
                    if (res.data.code == 1) {
                        console.log(res.data.message)
                    } else {
                        console.log(res.data.message)
                    }
                })
            },
            click(record, index) {
                let self = this
                return {
                    on: {
                        mousedown: () => {
                            this.downItem = record
                            this.downIndex = index
                        },
                        mouseup: () => {
                            this.downItem.sNumber = index + 1
                            record.sNumber = this.downIndex + 1
                            this.$set(this.workBookDetailTableData, index, this.downItem)
                            this.$set(this.workBookDetailTableData, this.downIndex, record)
                            this.downItem = ''
                            this.downIndex = ''
                        }
                    }
                }
            },
            _uploadCourceIcon(e) {
                this.__uploadFile__(e.target.files).then(res => {
                    this.bookIconUrl = res[0].FileUrl
                })
            },
            _questionShow(i) {
                this.QuestionId = this.workBookTableColumnsData[i].QuestionId
                this.QuestionIdIndex = i
                this.questionVisible = true
            },
            _questionClose() {
                this.questionVisible = false
            },
            _materialVisible(data) {
                this.questionVisible = false
                this.QuestionId = ''
                if (data) {
                    this.workBookTableColumnsData[this.QuestionIdIndex].Title = data.Title
                }
            },
            _reset() {
                this.qTitle = ''
                this.searchStatus = ''
                this.searcheSchedule_1 = ''
                this.searcheSchedule_2 = ''
                this.searcheSchedule_3 = ''
                this.features = ''
            },
            _categoryOptHandleForWorkBook(
                WorkBookId,
                Pid,
                category_id,
                Key,
                NodeName,
                type
            ) {
                let data = {
                    WorkBookId: WorkBookId,
                    category_id: category_id,
                    Key: Key,
                    Name: NodeName,
                    Pid: Pid,
                    type: type,
                    UserId: userInfo.uid,
                    UserName: userInfo.name,
                    isNewAdd: this.isNewAdd
                }
                this.$axios.post(7000004, data, res => {
                    if (res.data.code == 1) {
                        if (type == 'add') {
                            let tmpPid = Pid
                            let categoryData = res.data.categoryData
                            if (this.isNewAdd == 1 && this.tmpWorkBookId == '') {
                                tmpPid = categoryData.tmpTopId
                                this.topid = categoryData.tmpTopId
                                this.tmpWorkBookId = categoryData.tmpWorkBookId
                            }
                            if (this.addTree == '') {
                                let length = this.bookCategoryData[0].Children.length
                                this.bookCategoryData[0].Children[length - 1].Id =
                                    categoryData.tmpNewCatetoryId
                                this.bookCategoryData[0].Children[length - 1].Pid = tmpPid
                            } else if (this.addTree.length == 1) {
                                let length = this.bookCategoryData[0].Children[this.addTree[0]]
                                    .Children.length
                                this.bookCategoryData[0].Children[this.addTree[0]].Children[
                                length - 1
                                    ].Id = categoryData.tmpNewCatetoryId
                                this.bookCategoryData[0].Children[this.addTree[0]].Children[
                                length - 1
                                    ].Pid = tmpPid
                            } else if (this.addTree.length == 2) {
                                let length = this.bookCategoryData[0].Children[this.addTree[0]]
                                    .Children[this.addTree[1]].Children.length
                                this.bookCategoryData[0].Children[this.addTree[0]].Children[
                                    this.addTree[1]
                                    ].Children[length - 1].Id = categoryData.tmpNewCatetoryId
                                this.bookCategoryData[0].Children[this.addTree[0]].Children[
                                    this.addTree[1]
                                    ].Children[length - 1].Pid = tmpPid
                            } else if (this.addTree.length == 3) {
                                let length = this.bookCategoryData[0].Children[this.addTree[0]]
                                    .Children[this.addTree[1]].Children[this.addTree[2]].Children
                                    .length
                                this.bookCategoryData[0].Children[this.addTree[0]].Children[
                                    this.addTree[1]
                                    ].Children[this.addTree[2]].Children[length - 1].Id =
                                    categoryData.tmpNewCatetoryId
                                this.bookCategoryData[0].Children[this.addTree[0]].Children[
                                    this.addTree[1]
                                    ].Children[this.addTree[2]].Children[length - 1].Pid = tmpPid
                            }
                        }
                        this.addTree = -1
                        this.setTree = -1
                        this.addTreeInput = ''
                        console.log(res.data.message)
                    } else {
                        console.log(res.data.message)
                    }
                })
            },
            _onConfigSet(e, record, qtype, ConfigSet) {
                let TypeId = ''
                let QuestionId = ''
                if (qtype == 1) {
                    TypeId = record.TypeId
                } else {
                    QuestionId = record.QuestionId
                }
                let subData = {
                    WorkBookId: this._getWorkBookId(),
                    CategoryId: this.selectCategoryId,
                    QuestionId: QuestionId,
                    TypeId: TypeId,
                    UserId: userInfo.uid,
                    value: ConfigSet
                }
                this.$axios.post(7000007, subData, res => {
                    console.log(res.data.message)
                })
            },
            _getWorkBookId() {
                let WorkBookId = ''
                if (this.isNewAdd == 1) {
                    WorkBookId = this.tmpWorkBookId
                } else {
                    WorkBookId = this.workBookTableData[this.setWorkBook].Id
                }
                return WorkBookId
            },
            _copy_qrcode_url(item,obj,type=0){
                let _qrcodeUrl;
                let self = this;
                let oInput = document.createElement('input');
                if (self.showStyleCheck == 0) {
                    if (!item.TypeId) {
                        _qrcodeUrl = 'https://q.qitianzhen.cn/homework/workBook?id='+item.QuestionId;
                    } else {
                        _qrcodeUrl = 'https://q.qitianzhen.cn/homework/workBook?workBook='+self.selectData.Id+'&questionTypeId='+item.TypeId;
                    }
                } else {
                    _qrcodeUrl = item.QrcodeUrl_Pad;
                }
                if(type===1){
                    _qrcodeUrl = 'https://q.qitianzhen.cn/homework/workBook?id='+item.QuestionId;
                }
                if(type===2){
                    _qrcodeUrl = 'https://q.qitianzhen.cn/homework/workBook?workBook='+self.selectData.Id+'&questionTypeId='+item.TypeId;
                }
                oInput.value = _qrcodeUrl;
                document.body.appendChild(oInput);
                oInput.select();
                document.execCommand('Copy');
                self.$message.success('复制成功');
                oInput.remove();
            },
        }
    }
</script>

<style lang="less" scoped>
    .workBook {
        height: 100%;
        .container {
            min-height: 100%;
            display: flex;
            justify-content: space-between;
            .content {
                flex: 1;
                margin-left: 15px;
                padding: 15px;
                padding-bottom: 40px;
                border: 1px solid #dfe0e6;
                background: #fff;
                border-radius: 5px;
                .row-title {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 10px;
                    span {
                        font-size: 16px;
                    }
                }
            }
            .workBook-detail {
                width: 100%;
                height: calc(~'100% - 80px');
                justify-content: flex-start;
                .tree {
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 10px;
                    height: 100%;
                    .row {
                        display: flex;
                        align-items: center;
                        margin: 20px 0;
                        p {
                            width: 70px;
                            color: #abb0bf;
                        }
                        input {
                            flex: 1;
                        }
                    }
                }
            }
            .crumbs {
                position: relative;
                height: 64px;
                padding-right: 30px;
                line-height: 64px;
                border-radius: 4px;
                background: #fff;
                text-align: center;
                color: #666;
                font-size: 14px;
                .return {
                    position: absolute;
                    top: 50%;
                    left: 25px;
                    transform: translateY(-50%);
                    font-size: 18px;
                    color: #9d9d9d;
                    cursor: pointer;
                }
                .crumbs-icon {
                    margin-left: 30px;
                    margin-right: 30px;
                    font-size: 14px;
                    color: #9d9d9d;
                }
                .crumbs-item {
                    position: relative;
                    cursor: pointer;
                    span {
                        position: absolute;
                        bottom: -24px;
                        left: 0;
                        width: 100%;
                        height: 2px;
                    }
                }
            }
        }
    }

    .alert-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        button {
            width: 120px;
        }
    }

    .tree {
        width: 200px;
        padding: 15px;
        border: 1px solid #dfe0e6;
        border-radius: 5px;
        background: #fff;
        .tree-title {
        }
    }

    .template-list {
        padding-top: 15px;
        .search-div {
            .condition {
                display: inline-block;
                margin-right: 20px;
                margin-bottom: 10px;
                span {
                    margin-right: 10px;
                }
            }
        }
        .icon-btn {
            font-size: 20px;
            margin-right: 8px;
            cursor: pointer;
        }

        .title-row {
            display: flex;
            flex-wrap: wrap;
            > li {
                position: relative;
                width: 140px;
                margin-right: 10px;
                margin-bottom: 15px;
                .template-img {
                    height: 100px;
                    overflow: hidden;
                    position: relative;
                }
                .template-title {
                    padding: 15px;
                    padding-bottom: 0;
                    font-size: 16px;
                    color: #2d3e5d;
                    span {
                        display: inline-block;
                        width: 180px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                .created-date {
                    padding: 0 15px;
                    padding-bottom: 10px;
                    color: #999;
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%);
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .pagination {
            text-align: right;
        }
    }

    .template-library {
        .title-row {
            > li {
                width: 200px;
                margin-right: 15px;
                margin-bottom: 15px;
                .template-img {
                    height: 160px;
                }
            }
        }
    }
    .copy_qrcode_url{
        cursor: pointer;
        color: #2473d5;
        font-size: 12px;
        display: inline-block;
        margin-left: 10px;
    }
</style>
